const { fakerIT } = require('@faker-js/faker');

const getMockPercentage = () => fakerIT.number.int({ min: 0, max: 4 }) * 5;

const getMockFeeValue = () =>
  fakerIT.helpers.maybe(() => fakerIT.number.int({ min: 2, max: 10 }) * 1000) ||
  0;

const singleJob = [
  {
    id: '1',
    deal_id: '1234',
    uuid: '4567',
    title:
      'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
    customer: {
      id: '45669',
      company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
      referent_name: fakerIT.person.fullName(),
    },
    customer_guests: new Array(4).fill('').map(() => ({
      uuid: fakerIT.string.uuid(),
      full_name: fakerIT.person.fullName(),
    })),
    hound_list_url: 'https://app.r-everse.com/hound/',
    reverse_job_url: 'https://app.r-everse.com/recruitment/job/2582',
    reverse_job_close_url: 'https://app.r-everse.com/recruitment/job/2582',
    pipedrive_url: 'https://r-everse.pipedrive.com',
    miro_url: 'https://reverse.miro.com',
    presentation_call_at: '2022-05-02 14:00:00',
    profiling_call_at: '2018-06-21 12:45:09',
    success_fee: '3.200,00 €',
    code: 'REV-20201007-8420',
    publish_date: '2020-10-13',
    closed_at: null,
    numbers: {
      hunt_too_slow: true,
      not_enough_seems_good_applications: false,
      days: {
        values: {
          days: {
            value: 34,
            url: null,
          },
        },
      },
      hunted: {
        values: {
          hunted: {
            value: 300,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      spontaneous: {
        values: {
          evaluated: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          unevaluated: {
            value: 3,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      spoke: {
        values: {
          spoke: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      interested: {
        values: {
          eligible: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          not_eligible: {
            value: 2,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      interested_but: {
        values: {
          brand: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          role: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          ral: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          location: {
            value: 10,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      seems_good: {
        values: {
          seems_good: {
            value: 28,
            url: null,
          },
        },
      },
      approved: {
        values: {
          approved: {
            value: 28,
            url: null,
          },
        },
      },
      new_applicant: {
        values: {
          new_applicant: {
            value: 28,
            url: null,
          },
        },
      },
      eligible: {
        values: {
          not_viewed: {
            value: 2,
            url: null,
          },
          viewed: {
            value: 3,
            url: null,
          },
          promoted: {
            value: 1,
            url: null,
          },
          not_promoted: {
            value: 4,
            url: null,
          },
        },
      },
      top_candidate: {
        values: {
          report_in_draft: {
            value: 1,
            url: null,
          },
          report_published: {
            value: 2,
            url: null,
          },
        },
      },
      interviewed: {
        values: {
          interviewed: {
            value: 2,
            url: null,
          },
        },
      },
      closing: {
        values: {
          interested: {
            value: 5,
            url: null,
          },
          to_see_again: {
            value: 1,
            url: null,
          },
          coming_offer: {
            value: 1,
            url: null,
          },
          offer_done: {
            value: 0,
            url: null,
          },
        },
      },
      declined: {
        values: {
          declined: {
            value: 2,
            url: null,
          },
        },
      },
      bingo: {
        values: {
          bingo: {
            value: 0,
            url: null,
          },
        },
      },
    },
    manager: {
      id: '39462',
      email: 'elettra.paladini@r-everse.com',
      first_name: 'Elettra',
      last_name: 'Paladini',
      icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
    },
    tasks: [
      {
        id: '10867',
        type: 'application_closing_4',
        customer: {
          id: '102714',
          company_name: 'BBC Cellpack GmbH',
        },
        job: {
          id: '2597',
          title: 'LEITER QUALITÄTS- UND UMWELTMANAGEMENT',
        },
        job_state_progress: 'Closing',
        cta: {
          type: 'external_link',
          text: 'Bingo o Missed?',
          url: 'https://app.r-everse.com/recruitment/closing_application/118829',
        },
        application: {
          candidate_full_name: 'Amanda Della Rocca',
          candidate_phone: '+4915233895990',
          candidate_email: 'a.della-rocca@gmx.de',
        },
        image_name: null,
        step: 1,
        title: "<b>Presenta l'offerta</b> a Amanda Della Rocca",
        task_descriptive_title:
          "ha facilitato l'assunzione di Amanda Della Rocca",
        viewed_at: {
          date: '2020-12-11 17:30:45.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: "Fai precedere l'invio dell'offerta da una tua telefonata.",
        completed_at: null,
        hot: true,
        manager: {
          id: '63953',
          email: 'claudia.neuhoff@r-everse.com',
          first_name: 'Claudia',
          last_name: 'Neuhoff',
          icon: 'https://app.r-everse.com/extra-images/claudia_neuhoff.png',
          jarvis_token:
            'cx6ZgvN_GEWaYaQ6B72csk:APA91bEHYFhOgKPs7xwPYzyKSbfR-A6qMidRYxn2IFoAsaJm19FYPdp3iuSKrhasEyrqEt_e1lki2EYz_zPx-wKijFUFFRy3pRLV7pJn4zL1L7Cl3YZFpbj12MmCnDBSdAoZUnXb3_Vb',
        },
        postponed_at: null,
      },
      {
        id: '7682',
        type: 'application_curtain_opening_waiting_for_feedback',
        customer: {
          id: '102714',
          company_name: 'BBC Cellpack GmbH',
        },
        job: {
          id: '2597',
          title: 'LEITER QUALITÄTS- UND UMWELTMANAGEMENT',
        },
        job_state_progress: 'Curtain Opening',
        cta: {
          type: 'external_link',
          text: 'Trascrivi il feedback',
          url: 'https://app.r-everse.com/customer/candidate_review/detail/119341',
        },
        application: {
          candidate_full_name: 'Andreas Klaiber',
          candidate_phone: '+4916094663933',
          candidate_email: 'klaiber.andreas@web.de',
        },
        image_name: null,
        step: 1,
        title:
          "<b>Richiedi feedback</b> su <b>Andreas Klaiber</b>, non l'hai ancora ricevuto",
        task_descriptive_title: 'ha acquisito il feedback su Andreas Klaiber',
        viewed_at: {
          date: '2020-11-12 17:20:06.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Il tempo corre... fai un follow up se non avevi concordato più tempo',
        completed_at: null,
        hot: false,
        manager: {
          id: '63953',
          email: 'claudia.neuhoff@r-everse.com',
          first_name: 'Claudia',
          last_name: 'Neuhoff',
          icon: 'https://app.r-everse.com/extra-images/claudia_neuhoff.png',
          jarvis_token:
            'cx6ZgvN_GEWaYaQ6B72csk:APA91bEHYFhOgKPs7xwPYzyKSbfR-A6qMidRYxn2IFoAsaJm19FYPdp3iuSKrhasEyrqEt_e1lki2EYz_zPx-wKijFUFFRy3pRLV7pJn4zL1L7Cl3YZFpbj12MmCnDBSdAoZUnXb3_Vb',
        },
        postponed_at: {
          date: '2020-12-08 11:01:50.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '10472',
        type: 'application_closing_1',
        customer: {
          id: '102714',
          company_name: 'BBC Cellpack GmbH',
        },
        job: {
          id: '2597',
          title: 'LEITER QUALITÄTS- UND UMWELTMANAGEMENT',
        },
        job_state_progress: 'Closing',
        cta: {
          type: 'external_link',
          text: 'Aggiorna il closing',
          url: 'https://app.r-everse.com/recruitment/closing_application/121790',
        },
        application: {
          candidate_full_name: 'Jörg Hofschild',
          candidate_phone: '+497742858686',
          candidate_email: 'claudia.neuhoff+hofschild@r-everse.com',
        },
        image_name: null,
        step: 1,
        title:
          'Come procediamo con <b>Jörg Hofschild</b>? <b>5 giorni fa</b> Nicole Waelti ha detto che <b>interessa</b>.',
        task_descriptive_title: 'ha facilitato la decisione su Jörg Hofschild',
        viewed_at: {
          date: '2020-12-10 17:42:19.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: "E' il momento di dare i consigli giusti. Di essere Consulente.",
        completed_at: null,
        hot: true,
        manager: {
          id: '63953',
          email: 'claudia.neuhoff@r-everse.com',
          first_name: 'Claudia',
          last_name: 'Neuhoff',
          icon: 'https://app.r-everse.com/extra-images/claudia_neuhoff.png',
          jarvis_token:
            'cx6ZgvN_GEWaYaQ6B72csk:APA91bEHYFhOgKPs7xwPYzyKSbfR-A6qMidRYxn2IFoAsaJm19FYPdp3iuSKrhasEyrqEt_e1lki2EYz_zPx-wKijFUFFRy3pRLV7pJn4zL1L7Cl3YZFpbj12MmCnDBSdAoZUnXb3_Vb',
        },
        postponed_at: {
          date: '2020-12-17 16:42:20.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '5466',
        type: 'job_with_incoming_JRA',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Searching',
        cta: {
          type: 'external_link',
          text: 'Condividi profili con il cliente',
          url: 'https://app.r-everse.com/customer/candidate_review/REV-20201007-8420',
        },
        application: [],
        image_name: 'hurry',
        step: 2,
        title: '<b>Prepara la JRA</b>: mancano 2 giorni e hai 0 "sembra buono"',
        viewed_at: {
          date: '2020-10-15 17:20:54.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Ogni telefonata con il candidato è una miniera di informazioni preziose che puoi portare al cliente',
        completed_at: {
          date: '2020-10-19 17:07:31.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        postponed_at: {
          date: '2020-12-19 17:07:31.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
      {
        id: '4936',
        type: 'new_job',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Profiling',
        cta: {
          type: 'external_link',
          text: 'Aggiorna la data della call',
          url: 'https://app.r-everse.com/job/edit_profiling_call_date/2582',
        },
        application: [],
        image_name: 'boost',
        step: 1,
        title:
          'C’è una nuova job per te! Fissa la data della <b>videocall di profiling</b> con <b>Michele Lafortezza</b>',
        viewed_at: {
          date: '2020-10-13 16:35:34.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Scrivi al cliente il prima possibile',
        completed_at: {
          date: '2020-10-13 16:36:03.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        postponed_at: null,
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
      {
        id: '4937',
        type: 'job_without_scout',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Profiling',
        cta: {
          type: 'external_link',
          text: 'Assegna o rimuovi scout',
          url: 'https://app.r-everse.com/manager/edit_scout/2582',
        },
        application: [],
        image_name: null,
        step: 2,
        title:
          'Qui <strong>manca</strong> la figura dello <strong>scout</strong>',
        viewed_at: {
          date: '2020-10-13 16:17:06.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Lo/a Scout ti può aiutare nella preparazione del profiling',
        completed_at: {
          date: '2020-10-13 16:35:13.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        hot: false,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
      {
        id: '5218',
        type: 'new_job_with_exploration_call_done',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Profiling',
        cta: {
          type: 'external_link',
          text: 'Pubblica la job',
          url: 'https://app.r-everse.com/manager/validate/2582',
        },
        application: [],
        image_name: null,
        step: 3,
        title: 'È il momento di <b>pubblicare la job</b> su R-Everse',
        viewed_at: {
          date: '2020-10-13 16:36:22.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Differenziati dalla massa, scrivi un annuncio attrattivo',
        completed_at: null,
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
      {
        id: '5219',
        type: 'job_recap_email_to_be_sent',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Profiling',
        cta: {
          type: 'completer',
          text: 'Tutto fatto!',
          url: null,
        },
        application: [],
        image_name: null,
        step: 3,
        title:
          'Invia un <strong>recap della call di profiling</strong> a Michele Lafortezza',
        viewed_at: {
          date: '2020-10-13 16:36:12.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: null,
        completed_at: {
          date: '2020-10-13 16:36:17.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
      {
        id: '5220',
        type: 'job_update_jra_data',
        customer: {
          id: '45669',
          company_name: 'KEMIN NUTRISURANCE EUROPE SRL',
        },
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        job_state_progress: 'Profiling',
        cta: {
          type: 'external_link',
          text: 'Aggiorna la data della JRA',
          url: 'https://app.r-everse.com/job/edit_presentation_call_date/2582',
        },
        application: [],
        image_name: null,
        step: 3,
        title: 'Pianifica la <b>data della JRA</b>',
        viewed_at: {
          date: '2020-10-13 18:36:07.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Fissala prima possibile. Il tempo che passa è nemico del Bingo',
        completed_at: {
          date: '2020-10-14 09:58:39.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        hot: false,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
      },
    ],
    notes: [
      {
        id: '534',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
        favorite: false,
        title: 'Invio file mappatura',
        body: "Ieri ho inviato i quattro file con la mappatura completa. L'abbiamo fatta a livello mondiale quindi sono emersi davvero tanti nomi di persone, soprattutto sales manager, che operano anche in Asia, America... Le aziende competitors indicate erano perlopiù multinazionali con migliaia di dipendenti, ho cercato di ridurre il focus solo a coloro che provengono dal tessile. I dati sono spuri, su LinkedIn i profili potrebbero non essere aggiornati, ma questo possiamo verificarlo e avere la certezza solo se li sentiamo. Aspettiamo di sapere cosa vogliono fare e se procediamo con la JRA delle figure",
        job_state_progress: 'Searching',
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        created_at: {
          date: '2020-10-21 10:24:50.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '395',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
        },
        favorite: false,
        title: 'Restituzione mappatura',
        body: 'Fissato confronto lunedì 19',
        job_state_progress: 'Profiling',
        job: {
          id: '2582',
          title:
            'PACCHETTO PER 4 POSIZIONI (R&D MANAGER, SALES MANAGER, SALES DIRECTOR, MKT MANAGER)',
        },
        created_at: {
          date: '2020-10-13 15:53:53.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
    ],
    phases: [
      {
        name: 'Profiling',
        steps: {
          total: 3,
          completed: 3,
        },
      },
      {
        name: 'Searching',
        steps: {
          total: 2,
          completed: 1,
        },
      },
      {
        name: 'Curtain Opening',
        steps: {
          total: 2,
          completed: 0,
        },
      },
      {
        name: 'Scout Interviewing',
        steps: {
          total: 2,
          completed: 0,
        },
      },
      {
        name: 'Customer Interviewing',
        steps: {
          total: 3,
          completed: 0,
        },
      },
      {
        name: 'Closing',
        steps: {
          total: 5,
          completed: 0,
        },
      },
      {
        name: 'OnBoarding',
        steps: {
          total: 3,
          completed: 0,
        },
      },
    ],
    applications: [
      {
        candidate: 'Maria Iacovone',
        most_advanced_index: 1,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Maria Iacovone',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-04',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Maria Iacovone',
          },
        ],
      },
      {
        candidate: 'Antonio Scarlino',
        discarded: null,
        most_advanced_index: 6,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Antonio Scarlino',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-02',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Antonio Scarlino',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-02-08',
            description:
              'ha contattato Antonio Scarlino per informarsi sul colloquio in azienda',
          },
        ],
      },
      {
        candidate: 'Donato Antonio Cataldo',
        most_advanced_index: 2,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-02-04',
            description: 'ha acquisito il feedback su Donato Antonio Cataldo',
          },
        ],
      },
      {
        candidate: 'Daniele Ercolani',
        most_advanced_index: 7,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Daniele Ercolani',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-01-28',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Daniele Ercolani',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-03-02',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Daniele Ercolani',
          },
        ],
      },
      {
        candidate: 'Stefano Celia',
        most_advanced_index: 3,
        discarded: true,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Stefano Celia',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-04',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Stefano Celia',
          },
        ],
      },
      {
        candidate: 'Silvia De Grazia',
        most_advanced_index: 4,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-02-04',
            description: 'ha acquisito il feedback su Silvia De Grazia',
          },
        ],
      },
      {
        candidate: 'Mauro Gianfreda',
        most_advanced_index: 11,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Mauro Gianfreda',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-01-28',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Mauro Gianfreda',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-02-01',
            description:
              'ha contattato Mauro Gianfreda per informarsi sul colloquio in azienda',
          },
          {
            phase: 'Customer Interviewing',
            step: 3,
            date: '2021-02-04',
            description:
              'ha contattato Mauro Gianfreda per informarsi sul secondo colloquio in azienda',
          },
          {
            phase: 'Closing',
            step: 3,
            date: '2021-02-04',
            description: 'ha preparato il colloquio con Mauro Gianfreda',
          },
          {
            phase: 'Closing',
            step: 4,
            date: '2021-02-15',
            description: 'ha facilitato la trattativa con Mauro Gianfreda',
          },
          {
            phase: 'Closing',
            step: 5,
            date: '2021-02-16',
            description:
              'ha inserito la data di inizio lavoro di Mauro Gianfreda',
          },
        ],
      },
      {
        candidate: 'Viviana Mansi',
        most_advanced_index: 5,
        discarded: true,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Viviana Mansi',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-04',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Viviana Mansi',
          },
        ],
      },
      {
        candidate: 'Elisa Ricci',
        most_advanced_index: 9,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-01-27',
            description: 'ha acquisito il feedback su Elisa Ricci',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-02',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Elisa Ricci',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-02-08',
            description:
              'ha contattato Elisa Ricci per informarsi sul colloquio in azienda',
          },
        ],
      },
      {
        candidate: 'Nicola Nonnis',
        most_advanced_index: 10,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-02-09',
            description: 'ha acquisito il feedback su Nicola Nonnis',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-10',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Nicola Nonnis',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-02-17',
            description:
              'ha contattato Nicola Nonnis per informarsi sul colloquio in azienda',
          },
          {
            phase: 'Customer Interviewing',
            step: 3,
            date: '2021-03-02',
            description:
              'ha contattato Nicola Nonnis per informarsi sul secondo colloquio in azienda',
          },
          {
            phase: 'Closing',
            step: 3,
            date: '2021-03-09',
            description: 'ha preparato il colloquio con Nicola Nonnis',
          },
        ],
      },
      {
        candidate: 'Chamkila Jassal',
        most_advanced_index: 1,
        discarded: true,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-02-10',
            description: 'ha acquisito il feedback su Chamkila Jassal',
          },
        ],
      },
      {
        candidate: 'Alessandra La Face',
        most_advanced_index: 1,
        discarded: true,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-02-11',
            description: 'ha acquisito il feedback su Alessandra La Face',
          },
        ],
      },
      {
        candidate: 'Assuntina Magnante',
        most_advanced_index: 8,
        discarded: null,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-02-11',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Assuntina Magnante',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-02-17',
            description:
              'ha fissato il colloquio tra Cr\u00e9dit Agricole Italia S.p.A. e Assuntina Magnante',
          },
        ],
      },
    ],

    frozen: false,

    currencySymbol: '€',
    accommodationValuePercentage: getMockPercentage(),
    billedConfidenceFeeAmount: getMockFeeValue(),
    billedRetainerFeeAmount: getMockFeeValue(),
    bonusValuePercentage: getMockPercentage(),
    bonusValuePercentageGeneric: getMockPercentage(),
    carValue: getMockFeeValue(),
    contractUrl: fakerIT.internet.url(),
    healthInsuranceValuePercentage: getMockPercentage(),
    stocksValuePercentage: getMockPercentage(),
    successFeeMinimumValue: fakerIT.number.int({ min: 2, max: 10 }) * 1000,
    totalSellingPercentage: getMockPercentage(),
  },
  {
    id: '2515',
    title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
    customer: {
      id: '77371',
      company_name: 'RECORDATI INDUSTRIA CHIMICA E FARMACEUTICA S.P.A.',
    },
    hound_list_url:
      'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
    reverse_job_url: 'https://app.r-everse.com/recruitment/job/2515',
    reverse_job_close_url: 'https://app.r-everse.com/manager/close/2515',
    pipedrive_url: 'https://r-everse.pipedrive.com',
    success_fee: '9.500,00 €',
    code: 'REV-20200908-8147',
    publish_date: '2020-09-16',
    numbers: {
      days: {
        values: {
          days: {
            value: 34,
            url: null,
          },
        },
      },
      hunted: {
        values: {
          hunted: {
            value: 300,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      spontaneous: {
        values: {
          evaluated: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          unevaluated: {
            value: 3,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      spoke: {
        values: {
          spoke: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      interested: {
        values: {
          eligible: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          not_eligible: {
            value: 2,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      interested_but: {
        values: {
          brand: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          role: {
            value: 5,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          ral: {
            value: 20,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
          location: {
            value: 10,
            url: 'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
          },
        },
      },
      seems_good: {
        values: {
          seems_good: {
            value: 28,
            url: null,
          },
        },
      },
      approved: {
        values: {
          approved: {
            value: 28,
            url: null,
          },
        },
      },
      new_applicant: {
        values: {
          new_applicant: {
            value: 28,
            url: null,
          },
        },
      },
      eligible: {
        values: {
          not_viewed: {
            value: 2,
            url: null,
          },
          viewed: {
            value: 3,
            url: null,
          },
          promoted: {
            value: 1,
            url: null,
          },
          not_promoted: {
            value: 4,
            url: null,
          },
        },
      },
      top_candidate: {
        values: {
          report_in_draft: {
            value: 1,
            url: null,
          },
          report_published: {
            value: 2,
            url: null,
          },
        },
      },
      interviewed: {
        values: {
          interviewed: {
            value: 2,
            url: null,
          },
        },
      },
      closing: {
        values: {
          interested: {
            value: 5,
            url: null,
          },
          to_see_again: {
            value: 1,
            url: null,
          },
          coming_offer: {
            value: 1,
            url: null,
          },
          offer_done: {
            value: 0,
            url: null,
          },
        },
      },
      declined: {
        values: {
          declined: {
            value: 2,
            url: null,
          },
        },
      },
      bingo: {
        values: {
          bingo: {
            value: 0,
            url: null,
          },
        },
      },
    },

    frozen: false,
    tasks: [
      {
        id: '4569',
        type: 'application_closing_2',
        customer: {
          id: '77371',
          company_name: 'RECORDATI INDUSTRIA CHIMICA E FARMACEUTICA S.P.A.',
        },
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        job_state_progress: 'Closing',
        cta: {
          type: 'external_link',
          text: 'Aggiorna il closing',
          url: 'https://app.r-everse.com/recruitment/closing_application/110404',
        },
        application: {
          candidate_full_name: 'Francesca Elisabetta Celeste',
          candidate_phone: '+393474003450',
          candidate_email: 'f.celeste91@gmail.com',
        },
        image_name: null,
        step: 1,
        title:
          'Gestisci nuovo colloquio: <b>1 mese fa</b> Elisabetta Mauti ha detto che vuole <b>rivedere Francesca Elisabetta Celeste</b>',
        viewed_at: {
          date: '2020-10-05 13:57:27.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Prepara al meglio anche questo colloquio',
        completed_at: null,
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
      },
      {
        id: '4896',
        type: 'application_closing_2',
        customer: {
          id: '77371',
          company_name: 'RECORDATI INDUSTRIA CHIMICA E FARMACEUTICA S.P.A.',
        },
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        job_state_progress: 'Closing',
        cta: {
          type: 'external_link',
          text: 'Aggiorna il closing',
          url: 'https://app.r-everse.com/recruitment/closing_application/113164',
        },
        application: {
          candidate_full_name: "Andreina D'auria",
          candidate_phone: '+3393394023802',
          candidate_email: 'andreinadauria@gmail.com',
        },
        image_name: null,
        step: 1,
        title:
          'Gestisci nuovo colloquio: <b>1 mese fa</b> Elisabetta Mauti ha detto che vuole <b>rivedere Andreina D&#039;auria</b>',
        viewed_at: {
          date: '2020-10-12 09:30:49.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
        tip: 'Prepara al meglio anche questo colloquio',
        completed_at: null,
        hot: true,
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
      },
    ],
    notes: [
      {
        id: '924',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
        favorite: false,
        title: 'LUDOVICA CAPALDI - COLLOQUIO Feedback',
        body: 'Molto bene secondo lei, chiacchierata piacevole. Spera bene! Appena possibile le danno notizie, forse già prox settimana per il test e poi colloquio finale in azienda. Molto contenta, molto interessata al ruolo (contrattualistica, malattie rare) ',
        job_state_progress: 'Closing',
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        created_at: {
          date: '2020-11-06 15:43:08.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '685',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
        favorite: false,
        title: 'FRANCESCA CELESTE - COLLOQUIO TECNICO ',
        body: "Esame di 3 casi pratici, 2 contratti e 1 domanda, come avrebbe risolto la questione. Invio anche di documenti da svolgere a casa con calma.  Crede che sia andata bene, è stato un po' complesso, c'era molto da ragionare. Sa che ha toccato i punti che doveva toccare ma su alcune cose non è stato immediato. Farà questo ulteriore lavoro e poi vedrà. Impressione sua sempre buona, ha visto che Lorenzo è una persona tranquilla. ",
        job_state_progress: 'Closing',
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        created_at: {
          date: '2020-10-28 10:55:20.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '646',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
        favorite: false,
        title: 'LORENA SUSTER - COLLOQUIO TECNICO',
        body: '3 prove: 2 fatte e 1 da fare a casa. Contratti da rivedere, casi già risolti da Lorenzo ma voleva sapere anche suo parere in merito. Interessata come sempre ',
        job_state_progress: 'Closing',
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        created_at: {
          date: '2020-10-27 09:45:41.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '426',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
        favorite: false,
        title: 'Feedback secondi colloqui Michetti',
        body: "Portiamo avanti al colloquio in sede con Michetti 3 candidate: Suster, Celeste e D'auria. Aloisi spostata su Trucillo. Ci sarà prova tecnica",
        job_state_progress: 'Closing',
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        created_at: {
          date: '2020-10-15 09:35:41.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
      {
        id: '147',
        manager: {
          id: '39462',
          email: 'elettra.paladini@r-everse.com',
          first_name: 'Elettra',
          last_name: 'Paladini',
          icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
          jarvis_token:
            'eYNiTw4L5ucIeC29-h72_n:APA91bG7uHOxpyuL0Gx3qhj1NwoMCWwNs-4b7FO79vUqYaWJuospL0Z2CY8AOYEyqBYZV0Do5IAc_7Vhv-30bJXjaeaigdbaTQ6EfYkeX91rRXPLDq-rWiVuaQovrMo51QYYJcFrG9pb',
        },
        favorite: false,
        title: 'Feedback JRA',
        body: 'Fissiamo colloqui con tutti i candidati, video call con Elisabetta',
        job_state_progress: 'Curtain Opening',
        job: {
          id: '2515',
          title: 'LEGAL SPECIALIST INTERNAZIONALE - PHARMA',
        },
        created_at: {
          date: '2020-09-29 14:49:37.000000',
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      },
    ],
    phases: [
      {
        name: 'Profiling',
        steps: {
          total: 3,
          completed: 3,
        },
      },
      {
        name: 'Searching',
        steps: {
          total: 2,
          completed: 1,
        },
      },
      {
        name: 'Curtain Opening',
        steps: {
          total: 2,
          completed: 0,
        },
      },
      {
        name: 'Scout Interviewing',
        steps: {
          total: 2,
          completed: 0,
        },
      },
      {
        name: 'Customer Interviewing',
        steps: {
          total: 3,
          completed: 0,
        },
      },
      {
        name: 'Closing',
        steps: {
          total: 5,
          completed: 0,
        },
      },
      {
        name: 'OnBoarding',
        steps: {
          total: 3,
          completed: 0,
        },
      },
    ],
    applications: [
      {
        candidate: 'Anselmo Poerio',
        discarded: false,
        most_advanced_index: 19,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-04-13',
            description: 'ha acquisito il feedback su Anselmo Poerio',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-04-13',
            description: 'ha fissato il colloquio tra OCM Spa e Anselmo Poerio',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-04-14',
            description: 'ha fissato il colloquio tra OCM Spa e Anselmo Poerio',
          },
        ],
      },
      {
        candidate: 'Francesco Bartaloni',
        discarded: true,
        most_advanced_index: 20,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-03-29',
            description: 'ha acquisito il feedback su Francesco Bartaloni',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-03-29',
            description:
              'ha fissato il colloquio tra OCM Spa e Francesco Bartaloni',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-04-02',
            description:
              'ha fissato il colloquio tra OCM Spa e Francesco Bartaloni',
          },
          {
            phase: 'Customer Interviewing',
            step: 3,
            date: '2021-04-13',
            description:
              'ha contattato Francesco Bartaloni per informarsi sul colloquio in azienda',
          },
        ],
      },
      {
        candidate: 'Emilio Ribecco',
        discarded: false,
        most_advanced_index: 19,
        tasks: [
          {
            phase: 'Curtain Opening',
            step: 1,
            date: '2021-04-13',
            description: 'ha acquisito il feedback su Emilio Ribecco',
          },
          {
            phase: 'Curtain Opening',
            step: 2,
            date: '2021-04-13',
            description: 'ha fissato il colloquio tra OCM Spa e Emilio Ribecco',
          },
          {
            phase: 'Customer Interviewing',
            step: 2,
            date: '2021-04-15',
            description: 'ha fissato il colloquio tra OCM Spa e Emilio Ribecco',
          },
        ],
      },
    ],
  },
];

module.exports = {
  singleJob,
};
