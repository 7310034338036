import React from 'react';
import { NOTIFICATION_ALERT_STATES } from '../../constants/notifications';
import { useTranslation } from 'react-i18next';
import { DateTimeZonePicker } from '../DateTimeZonePicker';
import { Alert } from '@reverse-hr/pattern-library';

export const SetMeetingDateForm = ({
  datePickerProps,
  isErrorAlertVisible,
  timeZoneProps,
  title,
  onChange,
  onErrorAlertClose,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.setMeetingDate',
  });

  return (
    <div className="c-set-meeting-form">
      {!!isErrorAlertVisible && (
        <Alert
          modifier="c-alert--fixed"
          closable
          icon="icn-warning-outline-24"
          type={NOTIFICATION_ALERT_STATES.NEGATIVE}
          onClose={onErrorAlertClose}
        >
          {t('error')}
        </Alert>
      )}

      <h3 className="c-set-meeting-form__title">{title}</h3>

      <div className="c-set-meeting-form__form">
        <DateTimeZonePicker
          datePickerProps={datePickerProps}
          timeZoneProps={timeZoneProps}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
