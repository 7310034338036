import React from 'react';
import type { ComponentIconPosition } from '../../../types/sharedTypes';
import { Icon, IconName } from '../../Icon';
import { IconPosition } from '../constants/icon';

interface IIconProps {
  name?: IconName;
  position?: ComponentIconPosition;
}

export const ButtonIcon: React.FC<IIconProps> = props => {
  const { position, name, children } = props;

  const renderIconLeft = () =>
    position === IconPosition.LEFT &&
    name && (
      <span
        data-testid="button-icon-left"
        className="c-action__icon c-action__icon--main"
      >
        <Icon size={20} name={name || ''} />
      </span>
    );

  const renderIconRight = () =>
    position === IconPosition.RIGHT &&
    name && (
      <span
        data-testid="button-icon-right"
        className="c-action__icon c-action__icon--support"
      >
        <Icon size={20} name={name || ''} />
      </span>
    );

  return (
    <React.Fragment>
      {renderIconLeft()}
      {children}
      {renderIconRight()}
    </React.Fragment>
  );
};

ButtonIcon.defaultProps = {
  name: undefined,
  position: undefined,
};
