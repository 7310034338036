import classNames from 'classnames';
import React from 'react';
import type { DefaultComponentProps } from '../../types/components';
import type { IconName } from '../Icon';
import { Size, Type, Types } from './constants/button';
import { IconPosition } from './constants/icon';
import { ButtonIcon } from './fragments/Icon';
import type {
  ButtonLayout,
  ButtonPosition,
  ButtonSize,
  ButtonType,
} from './types';

export interface IButtonProps extends DefaultComponentProps {
  buttonType?: ButtonType;
  type?: ButtonLayout;
  label?: string;
  iconOnly?: boolean;
  icon?: IconName;
  iconPosition?: ButtonPosition;
  disabled?: boolean;
  shadow?: boolean;
  size?: ButtonSize;
  onClick?: () => void;
  modifier?: string;
}

export const Action: React.FC<IButtonProps> = ({
  buttonType = Type.BUTTON as ButtonType,
  type = Types.PRIMARY,
  iconPosition = IconPosition.LEFT as ButtonPosition,
  size = Size.DEFAULT as ButtonSize,
  modifier = '',
  label = undefined,
  icon = undefined,
  disabled = false,
  iconOnly = false,
  shadow = true,
  onClick = () => null,
}) => {
  const classnames = classNames({
    [`c-action c-action--${type}`]: true,
    'c-action--iconized': iconOnly,
    'c-action--slender': !shadow,
    'c-action__text--small c-action--small': size === Size.SMALL,
    [`${modifier}`]: modifier,
  });

  return (
    <button
      data-testid="button"
      type={buttonType}
      className={classnames}
      disabled={disabled}
      onClick={onClick}
    >
      <ButtonIcon name={icon} position={iconPosition}>
        {label && !iconOnly && (
          <span data-testid="button-label" className="c-action__text">
            {label}
          </span>
        )}
      </ButtonIcon>
    </button>
  );
};
