import { authFetch } from '../utilities/session';

export const apiClientInterviewPost = async ({ applicationId, formData }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-interviews`,
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const apiClientInterviewFetch = async ({ applicationId, interviewId }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-interviews/${interviewId}`,
    method: 'GET',
  });

export const apiClientInterviewUpdate = async ({
  applicationId,
  interviewId,
  formData,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-interviews/${interviewId}`,
    method: 'PUT',
    body: JSON.stringify(formData),
  });

export const apiClientInterviewDelete = async ({
  applicationId,
  interviewId,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-interviews/${interviewId}`,
    method: 'DELETE',
  });

export const apiApplicationClientInterviewsFetch = async ({
  applicationId,
  currentPage = 1,
  itemsPerPage = 9999,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-interviews`,
    query: { currentPage, itemsPerPage },
  });
