import React, { type FC, useMemo } from 'react';
import type {
  TSelectCustomOptionListItemProps,
  TSelectOption,
} from '@/components/Select';
import { SelectOptionListItem } from '@/components/Select/SelectOptionListItem';
import { useDebounce } from '@/hooks/use-debounce';

interface TSelectOptionListProps {
  onSelectedOptionChange: (optionValue: TSelectOption) => void;
  options: TSelectOption[];
  searchQuery: string;
  selectedOption: TSelectOption | null;
  CustomOptionListItem: FC<TSelectCustomOptionListItemProps> | undefined;
}

export const SelectOptionList: FC<TSelectOptionListProps> = ({
  onSelectedOptionChange,
  options,
  searchQuery,
  selectedOption,
  CustomOptionListItem,
}) => {
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 300);

  const filteredOptions = useMemo(
    () =>
      options.filter(option =>
        option.label.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
      ),
    [debouncedSearchQuery],
  );

  return (
    <ul className="c-select__option-list">
      {filteredOptions.map(option => (
        <SelectOptionListItem
          key={option.value}
          onSelectedOptionChange={onSelectedOptionChange}
          option={option}
          selectedOption={selectedOption}
          CustomOptionListItem={CustomOptionListItem}
        />
      ))}
    </ul>
  );
};
