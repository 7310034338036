import React from 'react';
import { Action, Icon } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';

export const ClientInterviewListItem = ({
  clientInterview,
  onEditInterviewClick,
  onDeleteInterviewClick,
}) => {
  const { t } = useTranslation();

  const scheduleDate = new Date(clientInterview.scheduledAtUtc);

  const handleOnEditInterviewClick = () =>
    onEditInterviewClick({
      applicationId: clientInterview.applicationUuid,
      interviewId: clientInterview.id,
      candidateFullName: clientInterview.candidateFullName,
    });

  const handleOnDeleteInterviewClick = () => {
    onDeleteInterviewClick({
      applicationId: clientInterview.applicationUuid,
      interviewId: clientInterview.id,
    });
  };

  return (
    <li className="c-client-interviews__item">
      <div className="c-client-interviews__item-info">
        <div className="c-client-interviews__item-scheduled-at">
          <p className="c-client-interviews__item-time">
            {t('common.formatting.date.time', { time: scheduleDate })}
          </p>

          <p className="c-client-interviews__item-date">
            <strong>
              {t('common.formatting.date.date', { date: scheduleDate })}
            </strong>
          </p>
        </div>

        <Icon
          modifier="c-client-interviews__item-icon"
          name="icn-calendar-event-24"
          size={24}
        />

        <p className="c-client-interviews__item-description">
          {clientInterview.candidateFullName}
        </p>
      </div>

      <div className="c-client-interviews__item-actions-list">
        <Action
          modifier="c-client-interviews__item-action"
          label={t('clientInterviews.list.editItemAction')}
          size="small"
          type="outline"
          onClick={handleOnEditInterviewClick}
        />

        <Action
          modifier="c-client-interviews__item-action"
          label={t('clientInterviews.list.deleteItemAction')}
          size="small"
          type="simple-text"
          shadow={false}
          icon="icn-trash-full-24px"
          iconPosition="right"
          onClick={handleOnDeleteInterviewClick}
        />
      </div>
    </li>
  );
};
