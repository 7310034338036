import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { Parser } from 'html-to-react';
import { renderToString } from 'react-dom/server';
import { Icon } from '@reverse-hr/pattern-library';

const Multiselect = ({
  options,
  defaultValue,
  placeholder,
  menuPlacement,
  optionIcon,
  shouldClear,
  clearable,
  externalClassName,
  onChange,
}) => {
  const htmlParser = new Parser();
  const selectRef = useRef(null);

  useEffect(() => {
    if (!!shouldClear && selectRef.current) {
      selectRef.current.select.clearValue();
    }
  }, [shouldClear]);

  const Option = props => {
    // console.log(props)
    const _props = {
      ...props,
      innerProps: {
        ...props.innerProps,
        onClick: () => {
          if (props.data.onClick) {
            props.data.onClick(props.data);
          } else {
            props.innerProps.onClick();
            onChange(props.data);
          }
          if (shouldClear) {
            props.setValue('clear');
          }
        },
      },
      theme: {
        ...props.theme,
        colors: {
          ...props.theme.colors,
          primary: 'RGB(var(--primary-80))',
        },
      },
      children: htmlParser.parse(
        `${
          optionIcon
            ? `<span className="c-select__option-icon">${renderToString(
                <Icon name="icn-closing-24px" />,
              )}</span>`
            : ''
        } ${props.children}`,
      ),
    };
    return <components.Option {..._props} />;
  };

  const colors = {
    primary: 'RGB(var(--primary-80))',
    neutral20: 'RGB(var(--primary-80))',
    neutral30: 'RGB(var(--primary-80))',
    neutral50: 'RGB(var(--black))',
  };

  const defaultOptions = {
    options,
    defaultValue,
    // https://react-select.com/props#select-props
    isMulti: false,
    autoFocus: false,
    placeholder,
    className: 'c-select',
    classNamePrefix: 'c-select',
    closeMenuOnSelect: true,
    isDisabled: false,
    //isOptionDisabled: option => option.value === "vanilla",
    //isOptionSelected: option => option.value === "pistacchio",
    isSearchable: true,
    isClearable: clearable,
    minMenuHeight: 140,
    maxMenuHeight: 300,
    menuPlacement, // "bottom", "auto"
    menuPosition: 'absolute', // "fixed"
    theme: theme => ({
      // https://react-select.com/styles#style-object
      borderRadius: 0,
      colors: {
        ...theme.colors,
        ...colors,
      },
      spacing: {
        menuGutter: 8,
      },
    }),
    styles: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused
          ? 'RGB(var(--primary-80))'
          : 'RGB(var(--grey-40))',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected
          ? 'RGB(var(--primary-80))'
          : 'transparent',
        color: state.isSelected ? 'RGB(var(--white))' : 'RGB(var(--black))',
      }),
    },
  };

  /**
   * Tanti componenti interni (tutti?) si possono sostituire
   * Ecco la lista completa: https://react-select.com/components
   */

  return (
    <Select
      {...defaultOptions}
      ref={selectRef}
      components={{
        Option,
      }}
      className={`${externalClassName} c-select ${
        defaultOptions.menuPlacement === 'top'
          ? 'c-select--top'
          : 'c-select--bottom'
      }`}
    />
  );
};

Multiselect.defaultProps = {
  menuPlacement: 'top',
  optionIcon: false,
  shouldClear: true,
  clearable: false,
  onChange: () => {},
};

export default Multiselect;
