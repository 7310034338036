import React, { FC } from 'react';
import { Action, IButtonProps } from '../Action';

interface TProps {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  onCloseModalClick: () => void;
  cancelCtaProps?: IButtonProps;
  confirmCtaProps?: IButtonProps;
}

export const Modal: FC<TProps> = ({
  isOpen,
  title,
  subtitle,
  onCloseModalClick,
  cancelCtaProps,
  confirmCtaProps,
  children,
}): JSX.Element | null => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="c-modal is-open" aria-hidden={isOpen}>
      <div className="c-modal__overlay" tabIndex={-1} data-micromodal-close="">
        <div
          className="c-modal__container"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-sample-title"
        >
          <button
            className="c-modal__close"
            aria-label="Close"
            type="button"
            data-micromodal-close=""
            onClick={onCloseModalClick}
          />

          <div className="c-modal__inner">
            {title && (
              <div className="c-modal__head">
                <div className="c-modal__title">{title}</div>
              </div>
            )}
            <div className="c-modal__body">
              <div className="c-modal__subtitle">{subtitle}</div>
              <div className="c-modal__text">{children}</div>
            </div>

            <div className="c-modal__footer">
              <div className="c-modal__action">
                <Action {...cancelCtaProps} />
              </div>
              <div className="c-modal__action">
                <Action {...confirmCtaProps} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
