import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { ActionWithLoader } from '../../ActionWithLoader';
import { Alert, ImageStepper } from '@reverse-hr/pattern-library';
import { TaskClosingInterview } from './TaskClosingInterview';
import { TaskClosingExpectedSuccessDate } from './TaskClosingExpectedSuccessDate';
import { useExpectedSuccessDate } from './use-expected-success-date';
import { useInterviewForm } from './use-interview-form';
import { useApplication } from './use-application';
import {
  CLIENT_FEEDBACK_TYPES,
  CLOSING_STEP_KEY_LIST,
  CLOSING_STEP_KEY_MAP_TO_CLIENT_FEEDBACK_TYPE,
} from '../../../constants/applications';
import { TaskClosingOffer } from './TaskClosingOffer';
import { useOfferDetails } from './use-offer-details';

export const TaskClosingContext = createContext({});

export const TaskClosing = ({ isOfferFormFocused }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.closing',
  });

  const [alertError, setAlertError] = useState(null);

  const { application, onModalClose, setAlternativeTitle, job, task } =
    useContext(TaskModalContext);

  const { currentStep, onClientFeedbackSubmit, setCurrentStep } =
    useApplication(application, setAlertError);

  const {
    expectedSuccessDate,
    onExpectedSuccessDateChange,
    onExpectedSuccessDateSubmit,
  } = useExpectedSuccessDate(application, setAlertError);

  const {
    isInterviewFormValid,
    onClientInterviewFormChange,
    onInterviewFormSubmit,
  } = useInterviewForm(application, currentStep, setAlertError);

  const {
    isOfferDetailsFormValid,
    onOfferDetailsFormChange,
    onOfferDetailsFormSubmit,
  } = useOfferDetails(application, currentStep, setAlertError);

  const candidateFullName = !!task.application
    ? task.application.candidate_full_name
    : null;

  const closingSteps = CLOSING_STEP_KEY_LIST.map(stepKey => ({
    description: t(`steps.${stepKey}.description`),
    image: `/jarvis/assets/images/closing/${stepKey}.png`,
    key: CLOSING_STEP_KEY_MAP_TO_CLIENT_FEEDBACK_TYPE[stepKey],
    title: t(`steps.${stepKey}.title`),
  }));

  const CurrentStepView = !!currentStep
    ? CLIENT_FEEDBACK_TYPE_TO_CLOSING_VIEWS_MAP[currentStep]
    : null;

  const isFooterCtaDisabled =
    !currentStep || !isInterviewFormValid || !isOfferDetailsFormValid;

  useEffect(() => {
    setAlternativeTitle(t('caption', { candidate: candidateFullName }));

    return () => setAlternativeTitle(null);
  }, []);

  const onSave = async () => {
    setAlertError(null);

    const onSaveRequests = [onExpectedSuccessDateSubmit, onSaveSyncRequests];

    await Promise.all(
      onSaveRequests.map(async onSaveRequest => await onSaveRequest()),
    );

    onModalClose();
  };

  const onAlertClose = () => setAlertError(null);

  const onSaveSyncRequests = async () => {
    await onClientFeedbackSubmit();
    await onInterviewFormSubmit();
    await onOfferDetailsFormSubmit();
  };

  return (
    <TaskClosingContext.Provider
      value={{
        application,
        isOfferFormFocused,
        job,
        onClientInterviewFormChange,
        onOfferDetailsFormChange,
      }}
    >
      <TaskModalBody className="c-task-closing">
        {!!alertError && (
          <Alert
            type="warning"
            icon="icn-warning-outline-24"
            modifier="c-alert--fixed"
            onClose={onAlertClose}
          >
            {alertError}
          </Alert>
        )}

        <p className="c-task-closing__title">
          {t('title', { candidate: candidateFullName })}
        </p>

        <p className="c-task-closing__subtitle">{t('subtitle')}</p>

        <ImageStepper
          className="c-task-closing__steps"
          currentStep={currentStep}
          steps={closingSteps}
          onStepSelection={setCurrentStep}
        />

        {!!currentStep && (
          <TaskClosingExpectedSuccessDate
            expectedSuccessDate={expectedSuccessDate}
            onChange={onExpectedSuccessDateChange}
          />
        )}

        {!!CurrentStepView && <CurrentStepView />}
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          label={t('footerCta')}
          disabled={isFooterCtaDisabled}
          onClick={onSave}
        />
      </TaskModalFooter>
    </TaskClosingContext.Provider>
  );
};

const CLIENT_FEEDBACK_TYPE_TO_CLOSING_VIEWS_MAP = {
  [CLIENT_FEEDBACK_TYPES.TAKEN_INTO_CONSIDERATION]: null,
  [CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_ADDITIONAL_INTERVIEWS]:
    TaskClosingInterview,
  [CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_AN_OFFER]: TaskClosingOffer,
};
