import React, { useState, useEffect } from 'react';

import TaskHandle from './TaskHandle';
import Readmore from './Readmore';
import { Icon } from '@reverse-hr/pattern-library';
import { TaskNoteActionMenu } from './TaskNote/TaskNoteActionMenu';
import { TaskNoteForm } from './TaskNote/TaskNoteForm';

const TaskNote = ({
  job,
  compact,
  readonly,
  editing,
  note: { id, title, manager, body, job_state_progress, favorite, created_at },
  onUpdate,
  onRemove,
}) => {
  const getModifier = () => {
    let result = '';

    if (compact) {
      result += ' c-task--note-compact';
    }

    if (readonly) {
      result += ' c-task--note-readonly';
    }

    return result;
  };

  const modifier = getModifier();
  const [editMode, setEditMode] = useState(editing);

  const initialModel = {
    title: {
      value: title,
      valid: true,
    },
    body: {
      value: body,
      valid: true,
    },
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async e => {
    try {
      const response = await onUpdate({
        ...e,
        job_id: job.id,
        note_id: id,
      });
      if (response) {
        setEditMode(false);
      }
    } catch (error) {}
  };

  const handleRemove = () => {
    setEditMode(false);
    onRemove({ job_id: job.id, note_id: id });
  };

  const handleFavorite = favorite => {
    onUpdate({ job_id: job.id, note_id: id, favorite });
  };

  useEffect(() => {
    setEditMode(editing);
  }, [editing]);

  return (
    <div className={`c-task c-task--note ${modifier}`} data-testid="task-note">
      {!compact && !readonly && (
        <div className="c-task__header">
          <TaskHandle
            phase={job_state_progress}
            date={created_at.date}
            favorite={favorite}
            manager={manager}
            onEdit={handleEdit}
            onRemove={handleRemove}
            onFavorite={handleFavorite}
          />
        </div>
      )}

      {compact && !readonly && (
        <div className="c-task__menu">
          <TaskNoteActionMenu onRemove={handleRemove} onEdit={handleEdit} />
        </div>
      )}

      <div className="c-task__body">
        {!editMode && (
          <div className="c-task__note">
            <Readmore
              body={`${
                title ? `<strong class="title">${title}</strong>` : ''
              } ${body}`}
              id={id}
              modifier={
                compact
                  ? 'c-readmore--compact-note'
                  : readonly
                  ? 'c-readmore--readonly-note'
                  : 'c-readmore--note'
              }
            />
          </div>
        )}

        {editMode && (
          <TaskNoteForm
            handleSave={handleSave}
            initialTitle={initialModel.title.value}
            initialBody={initialModel.body.value}
          />
        )}
      </div>

      {readonly && favorite && (
        <div className="c-task__favorite">
          <Icon name="icn-favorite-on-24px" />
        </div>
      )}
    </div>
  );
};

TaskNote.defaultProps = {
  modifier: '',
  compact: false,
  readonly: false,
  editing: false,
  note: {
    title: '',
    id: '1',
    manager: {
      first_name: '',
      last_name: '',
      icon: '',
    },
    body: '',
    job_state_progress: '',
    favorite: false,
    created_at: {
      date: new Date().toISOString(),
      timezone_type: 3,
      timezone: 'Europe/Rome',
    },
  },
  onUpdate: () => {},
  onRemove: () => {},
};

export default TaskNote;
