import classNames from 'classnames';
import type { FC } from 'react';
import React, { ChangeEventHandler, useRef } from 'react';
import { Action } from '@/components/Action';
import type { IconName } from '@/components/Icon';
import { Icon } from '@/components/Icon';
import { Pictogram } from '@/components/Pictogram';

export interface TFileInputProps {
  acceptedFileTypes?: string;
  className?: string;
  fileName?: string;
  filePreviewIcon?: IconName;
  id?: string;
  isPreviewAvailable?: boolean;
  name?: string;
  replaceCtaLabel: string;
  uploadCtaLabel: string;
  onChange?: (uploadedFile: File) => void;
  onPreviewButtonClick?: () => void;
}

export const FileInput: FC<TFileInputProps> = ({
  acceptedFileTypes,
  className,
  fileName,
  filePreviewIcon,
  id,
  isPreviewAvailable,
  name,
  replaceCtaLabel,
  uploadCtaLabel,
  onChange,
  onPreviewButtonClick,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const wrapperClassNames = classNames('c-file-input', className);

  const ctaClassNames = classNames({
    'c-file-input__replace-cta': !!fileName,
    'c-file-input__upload-cta': !fileName,
  });

  const isPreviewButtonVisible = !!fileName && isPreviewAvailable;
  const isFileNamePictogramVisible = !!fileName && !isPreviewAvailable;
  const ctaType = fileName ? 'outline' : 'raw';
  const ctaLabel = fileName ? replaceCtaLabel : uploadCtaLabel;
  const ctaIcon = fileName ? undefined : 'icn-cloud-up-24';

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const uploadedFileList = target.files ?? [];
    const [uploadedFile] = uploadedFileList;

    if (!uploadedFile || !onChange) {
      return;
    }

    onChange(uploadedFile);
  };

  const onUploadButtonClick = () => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    inputRef.current.click();
    inputRef.current.focus();
  };

  return (
    <div className={wrapperClassNames}>
      <input
        accept={acceptedFileTypes}
        id={id}
        name={name}
        ref={inputRef}
        type="file"
        hidden
        onChange={onInputChange}
      />

      {isPreviewButtonVisible && (
        <button
          className="c-file-input__preview"
          onClick={onPreviewButtonClick}
        >
          <Pictogram icon={filePreviewIcon}>{fileName}</Pictogram>
          <Icon name="icn-show-24" />
        </button>
      )}

      {isFileNamePictogramVisible && (
        <div className="c-file-input__preview">
          <Pictogram icon={filePreviewIcon}>{fileName}</Pictogram>
        </div>
      )}

      <Action
        icon={ctaIcon}
        label={ctaLabel}
        modifier={ctaClassNames}
        onClick={onUploadButtonClick}
        type={ctaType}
      />
    </div>
  );
};
