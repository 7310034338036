import React, { useContext } from 'react';
import ActionTypes from '../../../constants/task/actionTypes';
import { TaskModalContext } from '../../TaskModal';
import { apiTaskUpdate } from '../../../api/task';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../../../constants/task';

export const useTaskCompleter = ({ trackCtaClick, setIsErrorAlertVisible }) => {
  const { onModalClose, setAlternativeView, task } =
    useContext(TaskModalContext);

  const onCompleterActionClick = async () => {
    setIsErrorAlertVisible(false);

    if (task.cta.type === ActionTypes.SCHEDULE_CLIENT_INTERVIEW) {
      return setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLIENT_INTERVIEW);
    }

    try {
      await apiTaskUpdate({
        taskId: task.id,
        payload: { completed_at: true },
      });

      trackCtaClick();
      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return { onCompleterActionClick };
};
