import { FileInput, Label } from '@reverse-hr/pattern-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OfferDetailsFormDocument = ({ id, letter, label, onChange }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.dateAndDocuments',
  });

  const [letterFileName, setLetterFileName] = useState(null);
  const [letterPreviewUrl, setLetterPreviewUrl] = useState('');

  const onLetterChange = async uploadedFile => {
    const convertedUploadedFile = await getFileAsBase64(uploadedFile);
    const blobData = new Blob([uploadedFile], { type: uploadedFile.type });

    setLetterFileName(uploadedFile.name);
    setLetterPreviewUrl(URL.createObjectURL(blobData));
    onChange(convertedUploadedFile);
  };

  const onPreviewButtonClick = () => {
    if (!letterPreviewUrl) {
      return;
    }

    window.open(letterPreviewUrl, '_blank');
  };

  useEffect(() => {
    if (!!letter && !!letter.name) {
      setLetterFileName(letter.name);
      setLetterPreviewUrl(letter.downloadUrl);
    }
  }, [letter]);

  return (
    <div className="c-offer-details-form__field">
      <Label modifier="c-offer-details-form__label" id={id} label={label} />

      <FileInput
        fileName={letterFileName}
        filePreviewIcon="icn-file-pdf-24px"
        id={id}
        name={id}
        replaceCtaLabel={t('replace')}
        uploadCtaLabel={t('upload')}
        acceptedFileTypes="application/pdf"
        isPreviewAvailable
        onChange={onLetterChange}
        onPreviewButtonClick={onPreviewButtonClick}
      />
    </div>
  );
};

const getFileAsBase64 = uploadedFile => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = function onLoadEnd() {
      const content = this.result.split(',', 2)[1];
      resolve(content);
    };

    reader.readAsDataURL(uploadedFile);
  });
};
