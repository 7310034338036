import React, { useContext, useState } from 'react';
import { ClientInterviewForm } from '../../ClientInterviewForm';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { ActionWithLoader } from '../../ActionWithLoader';
import { useTranslation } from 'react-i18next';
import { apiClientInterviewPost } from '../../../api/client-interviews';
import { Alert } from '@reverse-hr/pattern-library';

export const TaskClientInterview = () => {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [submitError, setSubmitError] = useState(null);
  const [clientInterviewData, setClientInterviewData] = useState({});
  const { job, onModalClose, task } = useContext(TaskModalContext);

  const onFormChange = ({ formData, isFormValid }) => {
    setIsSubmitDisabled(!isFormValid);
    setClientInterviewData(formData);
  };

  const onSubmitClick = async () => {
    try {
      await apiClientInterviewPost({
        applicationId: task.application.uuid,
        formData: clientInterviewData,
      });

      onModalClose();
    } catch (error) {
      handleSubmitError(error);
    }
  };

  const handleSubmitError = error => {
    const { errors, detail } = error;

    if (!!errors) {
      return setSubmitError(
        t(
          `clientInterviews.${errors[0].message}`,
          t('clientInterviews.errors.generic'),
        ),
      );
    }

    if (!!detail) {
      return setSubmitError(detail);
    }

    setSubmitError(t('clientInterviews.errors.generic'));
  };

  return (
    <>
      <TaskModalBody>
        {!!submitError && (
          <Alert
            modifier="c-alert--fixed"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={() => setSubmitError(null)}
          >
            {submitError}
          </Alert>
        )}

        <ClientInterviewForm
          onChange={onFormChange}
          candidateFullName={task.application.candidate_full_name}
          jobCustomer={job.customer}
          jobCustomerGuests={job.customer_guests}
        />
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          label={t('clientInterviews.form.footerCta')}
          icon="icn-handshake-24"
          disabled={isSubmitDisabled}
          onClick={onSubmitClick}
        />
      </TaskModalFooter>
    </>
  );
};
