import React from 'react';
import type { DefaultComponentProps } from '../../types/components';

export const icons = [
  'icn-LinkedIn-24',
  'icn-brief-bag-24',
  'icn-bulb-24',
  'icn-calendar-24',
  'icn-calendar-event-24',
  'icn-cast-24',
  'icn-chat-24',
  'icn-check-24',
  'icn-check-all-24',
  'icn-chevron-up-24',
  'icn-chevron-down-24',
  'icn-chevron-big-down-24',
  'icn-chevron-big-left-24',
  'icn-chevron-big-right-24',
  'icn-chevron-big-up-24',
  'icn-circle-check-outline-24',
  'icn-clock-24',
  'icn-close-big-24',
  'icn-close-small-24',
  'icn-cloud-down-24',
  'icn-cloud-up-24',
  'icn-dashboard-24',
  'icn-download-24',
  'icn-edit-24',
  'icn-error-outline-24',
  'icn-external-link-24',
  'icn-file-blank-outline-24',
  'icn-file-minus-24',
  'icn-flag-outline-24',
  'icn-folder-open-24',
  'icn-grid-vertical-24',
  'icn-group-24',
  'icn-group-alt-24',
  'icn-handshake-24',
  'icn-happy-24',
  'icn-heart-fill-24',
  'icn-heart-outline-24',
  'icn-hide-24',
  'icn-id-card-24',
  'icn-info-circle-24',
  'icn-link-24',
  'icn-list-minus-24',
  'icn-location-outline-24',
  'icn-log-out-24',
  'icn-magic-wand-24',
  'icn-mail-24',
  'icn-message-circle-24',
  'icn-minus-circle-24',
  'icn-mobile-24',
  'icn-money-24',
  'icn-more-vertical-24',
  'icn-note-24',
  'icn-path-24',
  'icn-phone-24',
  'icn-phone-outline-24',
  'icn-plus-24',
  'icn-plus-circle-outline-24',
  'icn-refresh-24',
  'icn-rocket-24',
  'icn-sad-24',
  'icn-search-24',
  'icn-search-small-24',
  'icn-send-24',
  'icn-settings-outline-24',
  'icn-share-outline-24',
  'icn-short-left-24',
  'icn-short-right-24',
  'icn-show-24',
  'icn-slider-01-24',
  'icn-super-happy-24',
  'icn-trophy-24',
  'icn-user-24',
  'icn-user-check-24',
  'icn-user-close-24',
  'icn-user-heart-24',
  'icn-user-plus-24',
  'icn-user-voice-24',
  'icn-warning-24',
  'icn-warning-outline-24',
  'reverse-logotype',
  'reverse-monogram',
  'reverse-payoff',
];

export type IconName = (typeof icons)[number];

export interface IconProps extends DefaultComponentProps {
  name: IconName;
  size?: 8 | 16 | 20 | 24 | 32 | 48 | 52;
  onClick?: () => void;
}

export const Icon = ({
  modifier,
  name,
  size,
  onClick,
}: IconProps): JSX.Element => {
  return (
    <svg className={`c-icon c-icon--${size} ${modifier}`} onClick={onClick}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  modifier: '',
  size: 20,
};
