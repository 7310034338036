import classNames from 'classnames';
import React, { type FC, useEffect, useRef, useState } from 'react';
import type { IconName } from '@/components/Icon';
import { SelectControl } from '@/components/Select/SelectControl';
import { SelectOptionList } from '@/components/Select/SelectOptionList';
import { useClickOutside } from '@/hooks/use-click-outside';

export interface TSelectProps {
  hasError?: boolean;
  isDisabled?: boolean;
  isSearchAvailable?: boolean;
  isSmall?: boolean;
  leftIcon?: IconName;
  options: TSelectOption[];
  placeholder: string;
  value?: string;
  customComponents?: {
    CustomControlLabel?: FC<TSelectCustomControlLabelProps>;
    CustomOptionListItem?: FC<TSelectCustomOptionListItemProps>;
  };
  onChange?: (selectedOption: TSelectOption['value']) => void;
}

export const Select: FC<TSelectProps> = ({
  hasError,
  isDisabled,
  isSearchAvailable,
  isSmall,
  leftIcon,
  options,
  placeholder,
  value,
  customComponents = {},
  onChange,
}) => {
  const [isOptionsListOpen, setIsOptionsListOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [selectedOption, setSelectedOption] = useState<TSelectOption | null>(
    null,
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(wrapperRef.current, () =>
    setIsOptionsListOpen(false),
  );

  const wrapperClassNames = classNames('c-select', {
    'c-select--error': hasError,
    'c-select--disabled': isDisabled,
    'c-select--small': isSmall,
  });

  const onFocusStatusChange = (isOpen: boolean) => {
    setIsOptionsListOpen(isOpen && !isDisabled);
  };

  const onSelectedOptionChange = (option: TSelectOption) => {
    setSelectedOption(option);
    setIsOptionsListOpen(false);

    if (onChange) {
      onChange(option.value);
    }
  };

  useEffect(() => {
    if (!selectedOption || value !== selectedOption.value) {
      const matchingOption = options.find(option => option.value === value);

      setSelectedOption(matchingOption || null);
    }
  }, [value]);

  return (
    <div className={wrapperClassNames} ref={wrapperRef}>
      <SelectControl
        isOptionListOpen={isOptionsListOpen}
        isSearchAvailable={isSearchAvailable}
        leftIcon={leftIcon}
        onFocusStatusChange={onFocusStatusChange}
        placeholder={placeholder}
        searchQuery={searchQuery}
        selectedOption={selectedOption}
        setSearchQuery={setSearchQuery}
        CustomControlLabel={customComponents.CustomControlLabel}
      />

      {isOptionsListOpen && (
        <SelectOptionList
          onSelectedOptionChange={onSelectedOptionChange}
          options={options}
          searchQuery={searchQuery}
          selectedOption={selectedOption}
          CustomOptionListItem={customComponents.CustomOptionListItem}
        />
      )}
    </div>
  );
};

export interface TSelectOption {
  value: string;
  label: string;
}

export interface TSelectCustomControlLabelProps {
  selectedOption: TSelectOption | null;
  placeholder: string;
}

export interface TSelectCustomOptionListItemProps {
  option: TSelectOption;
}
