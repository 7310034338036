import React, { useContext, useEffect } from 'react';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { TaskDetailsFooter } from './TaskDetailsFooter';
import { Prompter } from '../../Prompter';
import { TaskDetailsNotes } from './TaskDetailsNotes';
import { Alert } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';

export const TaskDetails = ({
  children,
  isErrorAlertVisible,
  isPauseButtonHidden,
  prompts,
  suggestion,
  title,
  setIsErrorAlertVisible,
}) => {
  const { t } = useTranslation();
  const { setAlternativeTitle, task } = useContext(TaskModalContext);

  const onErrorAlertClose = () => setIsErrorAlertVisible(false);

  useEffect(() => {
    setAlternativeTitle(title ?? null);
  }, []);

  return (
    <>
      <TaskModalBody className="c-task-details__body">
        {!!isErrorAlertVisible && (
          <Alert
            modifier="c-alert--fixed c-task-details__alert"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={onErrorAlertClose}
          >
            {t('errors.generic')}
          </Alert>
        )}

        <div className="c-task-details__prompts">
          <Prompter
            items={prompts}
            suggestion={!!suggestion ? suggestion : task.suggestion}
          />
        </div>

        {task.magnet ? null : (
          <TaskDetailsNotes setIsErrorAlertVisible={setIsErrorAlertVisible} />
        )}
      </TaskModalBody>

      <TaskModalFooter className="c-task-details__footer">
        <TaskDetailsFooter
          isPauseButtonHidden={isPauseButtonHidden}
          setIsErrorAlertVisible={setIsErrorAlertVisible}
        >
          {children}
        </TaskDetailsFooter>
      </TaskModalFooter>
    </>
  );
};
