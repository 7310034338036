export const utilityGetJobCurrentPhaseClassName = phases => {
  return phases.reduce((currentPhaseClassName, phase) => {
    const phaseClassName = JOB_PHASES_CLASS_NAMES[phase.name];
    const isPhaseToDo = phase.current_step === 0;
    const isPhaseInProgress = !isPhaseToDo && phase.current_step < phase.steps;
    const isPhaseCompleted = phase.current_step === phase.steps;

    if (isPhaseCompleted || isPhaseInProgress) {
      return phaseClassName;
    }

    return currentPhaseClassName;
  }, null);
};

const JOB_PHASES = {
  PROFILING: 'Profiling',
  SEARCHING: 'Searching',
  CURTAIN_OPENING: 'Curtain Opening',
  SCOUT_INTERVIEWING: 'Scout Interviewing',
  CUSTOMER_INTERVIEWING: 'Customer Interviewing',
  CLOSING: 'Closing',
  ON_BOARDING: 'OnBoarding',
};

const JOB_PHASES_CLASS_NAMES = {
  [JOB_PHASES.PROFILING]: 'profiling',
  [JOB_PHASES.SEARCHING]: 'searching',
  [JOB_PHASES.CURTAIN_OPENING]: 'curtain-opening',
  [JOB_PHASES.SCOUT_INTERVIEWING]: 'scout-interviewing',
  [JOB_PHASES.CUSTOMER_INTERVIEWING]: 'customer-interviewing',
  [JOB_PHASES.CLOSING]: 'closing',
  [JOB_PHASES.ON_BOARDING]: 'onboarding',
};
