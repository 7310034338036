import React, { useState } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { JOB_SET_DATE_MODAL_TYPES } from '../../constants/job';
import { format } from 'date-fns';
import { SetMeetingDateForm } from '../SetMeetingDateForm';
import { ActionWithLoader } from '../ActionWithLoader';

export const SetMeetingDateModal = ({
  initialValue,
  modalType,
  onChange,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.setMeetingDate',
  });

  const [date, setDate] = useState(
    format(
      initialValue ? new Date(initialValue) : new Date(),
      'yyyy-MM-dd HH:mm',
    ),
  );

  const onDatePickerChange = event => {
    setDate(event.date);

    if (onChange) {
      onChange(event.date);
    }
  };

  const onSubmitButtonClick = () =>
    onSubmit({
      name: MODAL_TYPE_TO_MODEL_NAME_MAP[modalType],
      value: date,
    });

  return (
    <div className="c-set-meeting-date-modal">
      <header className="c-set-meeting-date-modal__header">
        <Action
          iconOnly
          icon="icn-close-big-24"
          size="small"
          type="simple-text"
          onClick={onClose}
        />
      </header>

      <div className="c-set-meeting-date-modal__content">
        <SetMeetingDateForm
          datePickerProps={{
            isRequired: true,
            defaultValue: date,
            label: {
              id: 'set-meeting-date',
              text: t('dateLabel'),
            },
          }}
          title={t('title', { context: modalType })}
          onChange={onDatePickerChange}
        />
      </div>

      <footer className="c-set-meeting-date-modal__footer">
        <ActionWithLoader label={t('cta')} onClick={onSubmitButtonClick} />
      </footer>
    </div>
  );
};

const MODAL_TYPE_TO_MODEL_NAME_MAP = {
  [JOB_SET_DATE_MODAL_TYPES.PRESENTATION_CALL]: 'presentation_call_at',
  [JOB_SET_DATE_MODAL_TYPES.PROFILING_CALL]: 'profiling_call_at',
};
