import classNames from 'classnames';
import React, { FC } from 'react';
import { Icon, IconName } from '../Icon';
import type { TTableColumn, TTableSortOrder } from './types';
import { TABLE_SORT_ORDERS } from './types';

export interface TTableHeaderCellProps extends TTableColumn {
  isCurrentSortSelection: boolean;
  onClick: (columnName: string) => void;
  sortOrder: TTableSortOrder | undefined;
}

export const TableHeaderCell: FC<TTableHeaderCellProps> = ({
  content,
  isCurrentSortSelection,
  isSortable,
  name,
  onClick,
  sortOrder,
}) => {
  const wrapperClassNames = classNames('c-table__cell', {
    'c-table__cell--sortable': isSortable,
  });

  const sortIcon: IconName =
    sortOrder === TABLE_SORT_ORDERS.DESC
      ? 'icn-chevron-down-24'
      : 'icn-chevron-up-24';

  const onCellClick = (): void => {
    if (!isSortable) {
      return;
    }

    onClick(name);
  };

  return (
    <th className={wrapperClassNames} onClick={onCellClick}>
      <div className="c-table__content-wrapper">
        <span className="c-table__content">{content}</span>

        {isCurrentSortSelection && (
          <Icon name={sortIcon} modifier="c-table__icon" />
        )}
      </div>
    </th>
  );
};
