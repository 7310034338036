import React from 'react';
import { task } from '../__mock__/debug';
import user from '../__mock__/user';
import navigation from '../__mock__/navigation';
import jobsModule from '../__mock__/jobs';

import Task from '../components/Task';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Modal from '../components/Modal';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Action } from '@reverse-hr/pattern-library';

const DebugPage = () => {
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);

  const tasks = ['default', 'notification', 'highlighted', 'hint', 'image'];
  navigation.items.jobs.subitems = jobsModule.jobs
    ? jobsModule.jobs['20927'].map(job => {
        job.path = `/jobs/${job.id}`;
        return job;
      })
    : [];
  const buttons = [
    {
      type: 'primary',
    },
    {
      type: 'secondary',
    },
    {
      type: 'simple-text',
      modifier: 'c-prompter__cta',
    },
    {
      type: 'primary',
      icon: 'icn-hot-24px',
    },
  ];

  const handleButtonClick = () => {
    setModalOpened(true);
  };
  return (
    <section className="debug">
      <div className="c-tasks">
        <h2 className="debug__title">HEADER</h2>
        <Header navigation={navigation} user={user} />
      </div>

      <div className="c-tasks">
        <h2 className="debug__title">TASKS</h2>
        <div className="c-tasks__container o-tasks-layout">
          {tasks.map((c, i) => (
            <div key={`${c}-${i}`} className="o-tasks-layout__item">
              <Task {...task[c]} />
            </div>
          ))}
        </div>
      </div>

      <div className="c-tasks">
        <h2 className="debug__title">SIDEBAR</h2>
        <div className="o-layout">
          <div className={'o-layout--sidebar'}>
            <Sidebar loading={false} navigation={navigation} />
          </div>
        </div>
      </div>

      <div className="c-tasks">
        <h2 className="debug__title">Button and modal</h2>
        <div className="debug__buttons">
          {buttons.map((button, i) => (
            <div key={`button-${i}`} className="debug__button">
              <Action
                {...button}
                onClick={handleButtonClick}
                label="Open Modal"
              />
            </div>
          ))}
        </div>
        <div className="debug__modal">
          {isModalOpened ? <Modal>Hello Jarvis</Modal> : null}
        </div>
      </div>
    </section>
  );
};

export default DebugPage;
