import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TaskDetails } from '../TaskDetails';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../../constants/task';
import { Trans, useTranslation } from 'react-i18next';
import { Action } from '@reverse-hr/pattern-library';

export const TaskApplicationClosingThree = () => {
  const { t } = useTranslation();
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { setAlternativeView, task } = useContext(TaskModalContext);

  const prompts = [
    ...task.prompts,
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskApplicationClosingThreeClosingPrompt />,
    },
  ];

  const onOpenClosingModalClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLOSING, {
      isOfferFormFocused: true,
    });
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <Action
        onClick={onOpenClosingModalClick}
        label={t('task.detailsView.applicationClosingThree.cta')}
      />
    </TaskDetails>
  );
};

const TaskApplicationClosingThreeClosingPrompt = () => {
  const { job, setAlternativeView, task } = useContext(TaskModalContext);

  const onOpenClosingModalClick = event => {
    event.preventDefault();
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLOSING);
  };

  return (
    <p className="c-prompter__list-item-content--new">
      <Trans
        i18nKey="task.detailsView.applicationClosingThree.openClosingModalPrompt"
        values={{
          candidateFullName: task.application.candidate_full_name,
          customerFullName: job.customer.referent_name,
        }}
      >
        1
        <a href="#" onClick={onOpenClosingModalClick}>
          2
        </a>
      </Trans>
    </p>
  );
};
