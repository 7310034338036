import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';

import { ReactComponent as EmptyTasks } from '../assets/images/empty-tasks.svg';
import { ReactComponent as EmptyActivities } from '../assets/images/empty-activities.svg';
import ErrorGodzilla from '../assets/images/error-godzilla.png';

// tasks & activities: svg - https://r-everse-jarvis.tangible.is/images/stk-notice-white-yellow.svg
//

const Notice = ({ modifier, type, title, text, image }) => {
  const getModifier = () => {
    let result = modifier;
    if (['tasks', 'activities'].includes(type)) {
      result += ' c-notice--empty-vertical c-notice--h-centered';
    }
    if (type === 'notes') {
      result += ' c-notice--empty-partial';
    }
    if (['error-general', 'error-partial'].includes(type)) {
      result += ` c-notice--${type}`;
    }
    return result;
  };
  const _modifier = getModifier();

  return (
    <div data-testid="notice-container" className={`c-notice ${_modifier}`}>
      <div className="c-notice__content">
        {title && (
          <p data-testid="notice-title" className="c-notice__title">
            {title}
          </p>
        )}
        {text && (
          <p data-testid="notice-text" className="c-notice__text">
            {text}
          </p>
        )}
      </div>
      {type === 'tasks' && (
        <EmptyTasks data-testid="notice-image" className="c-notice__img" />
      )}
      {type === 'activities' && (
        <EmptyActivities data-testid="notice-image" className="c-notice__img" />
      )}
      {type === 'error-general' && (
        <img
          src={ErrorGodzilla}
          data-testid="notice-image"
          className="c-notice__img"
          alt="Error"
        />
      )}

      {type === 'error-partial' && (
        <div data-testid="notice-icon" className="c-notice__icon">
          <Icon name="icn-close-24px" />
        </div>
      )}
    </div>
  );
};

Notice.defaultProps = {
  modifier: '',
};
export default Notice;
