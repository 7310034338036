import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { FormField, RadioButton } from '@reverse-hr/pattern-library';
import { getValidation } from '../../../helpers/utils';
import Editor from '../../Editor';
import { ActionWithLoader } from '../../ActionWithLoader';
import { apiEmailPost } from '../../../api/email';
import { NotificationAlert } from '../../NotificationAlert';
import { NOTIFICATION_ALERT_STATES } from '../../../constants/notifications';

export const TaskSendEmail = ({ templates }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.sendEmail',
  });

  const isTemplateListValid = templates && templates.length > 0;

  const [isLoading, setIsLoading] = useState(false);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState(
    isTemplateListValid ? templates[0].template_id : null,
  );

  const [recipientValue, setRecipientValue] = useState('');
  const [carbonCopyValue, setCarbonCopyValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [bodyValue, setBodyValue] = useState('');
  const { setAlertMessage, setAlternativeView } = useContext(TaskModalContext);

  const {
    email: { validate: validateEmail },
    emails: { validate: validateEmails },
    required: { validate: validateRequired },
  } = getValidation(['email', 'emails', 'required']);

  const validateTo = value => validateRequired(value) && validateEmail(value);
  const validateCc = value => validateEmails(value);
  const validateSubject = value => validateRequired(value);

  const isFormValid = useMemo(() => {
    return (
      typeof validateTo(recipientValue) !== 'string' &&
      typeof validateCc(carbonCopyValue) !== 'string' &&
      typeof validateSubject(subjectValue) !== 'string'
    );
  }, [recipientValue, carbonCopyValue, subjectValue]);

  const selectedTemplate = useMemo(
    () =>
      templates.find(template => template.template_id === selectedTemplateId),
    [selectedTemplateId],
  );

  const onRecipientChange = ({ value }) => setRecipientValue(value);
  const onCarbonCopyChange = ({ value }) => setCarbonCopyValue(value);
  const onSubjectChange = ({ value }) => setSubjectValue(value);
  const onBodyChange = ({ value }) => setBodyValue(value);
  const onTemplateChange = ({ value }) => setSelectedTemplateId(Number(value));
  const onErrorAlertClose = () => setIsErrorAlertVisible(false);

  const onSubmit = async () => {
    setIsLoading(true);
    setIsErrorAlertVisible(false);

    try {
      await apiEmailPost({
        to: recipientValue,
        cc: carbonCopyValue,
        subject: subjectValue,
        body: bodyValue,
      });

      setAlertMessage(t('emailSent'));
      setAlternativeView(null);
    } catch (error) {
      setIsErrorAlertVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!selectedTemplate) {
      setRecipientValue(selectedTemplate.to ?? '');
      setCarbonCopyValue(selectedTemplate.cc ?? '');
      setSubjectValue(selectedTemplate.subject ?? '');
      setBodyValue(selectedTemplate.body ?? '');
    }
  }, [selectedTemplate]);

  return isTemplateListValid ? (
    <>
      <TaskModalBody className="c-task-send-email">
        <NotificationAlert
          isVisible={isErrorAlertVisible}
          onClose={onErrorAlertClose}
          status={NOTIFICATION_ALERT_STATES.NEGATIVE}
        >
          {t('errors.notSent')}
        </NotificationAlert>

        <div className="c-task-send-email__content">
          <div className="c-task-send-email__composition">
            <FormField
              className="c-task-send-email__field"
              id="to"
              type="input"
              label={t('recipient')}
              disabled={isLoading}
              required
              onChange={onRecipientChange}
              validateFn={validateTo}
              inputProps={{
                value: recipientValue,
              }}
            />

            <FormField
              className="c-task-send-email__field"
              id="cc"
              type="input"
              label={t('carbonCopy')}
              hint={t('carbonCopyHint')}
              disabled={isLoading}
              onChange={onCarbonCopyChange}
              validateFn={validateCc}
              inputProps={{
                value: carbonCopyValue,
              }}
            />

            <FormField
              className="c-task-send-email__field"
              id="subject"
              type="input"
              label={t('subject')}
              disabled={isLoading}
              required
              onChange={onSubjectChange}
              validateFn={validateSubject}
              inputProps={{
                value: subjectValue,
              }}
            />

            <Editor
              id="input-email-body"
              modelName="body"
              onChange={onBodyChange}
              content={bodyValue}
              disabled={isLoading}
            />
          </div>

          <div className="c-task-send-email__options">
            <p className="c-task-send-email__options-title">
              {t('optionsTitle')}
            </p>

            {templates.map(template => (
              <RadioButton
                key={`email-template-${template.template_id}`}
                id={`email-template-${template.template_id}`}
                modifier=" c-task-send-email__radio"
                name="email-template"
                value={template.template_id}
                onChange={onTemplateChange}
                checked={template.template_id === selectedTemplateId}
                label={template.template_name}
              />
            ))}
          </div>
        </div>
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          onClick={onSubmit}
          disabled={!isFormValid}
          buttonType="submit"
          label={t('sendButton')}
        />
      </TaskModalFooter>
    </>
  ) : null;
};
