import { action, thunk, computed } from 'easy-peasy';
import isEqual from 'lodash.isequal';
import { format } from 'date-fns';
import i18next from 'i18next';

export default {
  // state
  tasks: [],
  postponedTask: null,
  isLoading: true,
  isError: false,

  // actions
  setTasks: action((state, payload) => {
    state.tasks = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),
  // thunks
  updateTasksListWithUpdatedTaskItem: thunk(
    (actions, payload, { getState }) => {
      const { tasks } = getState();

      const newTasks = tasks.map(task =>
        task.id === payload?.id ? payload : task,
      );
      actions.setTasks(newTasks);
    },
  ),
  fetchTasks: thunk(
    async (actions, payload, { getState, getStoreActions, injections }) => {
      const { tasks, postponedTask } = getState();
      const { tasksService } = injections;
      try {
        const fetchedTasks = await tasksService.getTasks();
        if (typeof fetchedTasks !== 'object') {
          throw new Error('Tasks is not an array', fetchedTasks);
        }
        if (!!fetchedTasks) {
          actions.setIsError(false);

          // Rerenderizzo se qualsiasi cosa cambia nei tasks
          const shouldRerender = tasks.length
            ? !isEqual(fetchedTasks, tasks)
            : true;

          // Notifico solo se i tasks presenti cambiano
          const tasksIds = tasks.map(t => t.id);
          const fetchedTasksIds = fetchedTasks.map(t => t.id);
          const shouldNotify = tasks.length
            ? fetchedTasksIds.join('') !== tasksIds.join('')
            : false;

          if (shouldRerender) {
            actions.setTasks(fetchedTasks);
            const payload = {
              value: fetchedTasks.some(task => !task.viewed_at),
              item: 'tasks',
            };
            getStoreActions().navigation.setHasUpdates(payload);
          }

          if (shouldNotify) {
            const postponedId = postponedTask ? postponedTask.id : null;
            const newTasks = fetchedTasks.filter(t => !tasksIds.includes(t.id));
            const completedTasks = tasks.filter(
              t => !fetchedTasksIds.includes(t.id) && t.id !== postponedId,
            );

            // console.log(`Notify: ${newTasks.length} new tasks, ${completedTasks.length} completed`)

            const completedMessage = completedTasks.length
              ? completedTasks.length === 1
                ? i18next.t('notifications.completed-task')
                : i18next.t('notifications.completed-tasks', {
                    number: completedTasks.length,
                  })
              : '';
            const newTasksMessage = newTasks.length
              ? newTasks.length === 1
                ? i18next.t('notifications.new-task')
                : i18next.t('notifications.new-tasks', {
                    number: newTasks.length,
                  })
              : '';
            if (!!completedMessage || !!newTasksMessage) {
              getStoreActions().setNotifications({
                id: `${tasksIds.join('').slice(0, 10)}-${Date.parse(
                  new Date(),
                )}`,
                visible: true,
                types: ['positive', 'raw'],
                message: `${completedMessage} ${newTasksMessage}`,
              });
            }
            if (postponedTask) {
              getStoreActions().setNotifications({
                id: postponedTask.id,
                visible: true,
                types: ['positive', 'raw'],
                message: i18next.t('notifications.postponed-task', {
                  date: format(
                    new Date(postponedTask.postponed_at),
                    'dd/MM/yyyy',
                  ),
                }),
              });

              actions.setPostponedTask(null);
            }
          }
        }
      } catch (error) {
        console.warn(error);
        actions.setIsError(true);
      } finally {
        actions.setIsLoading(false);
      }
    },
  ),

  // computed
  hotTasks: computed(({ tasks }) =>
    !!tasks
      ? tasks.filter(
          t => !!t.hot && !t?.paused_at?.date && t.type !== 'application_bingo',
        )
      : [],
  ),
  toDoTasks: computed(({ tasks }) =>
    !!tasks
      ? tasks.filter(
          t => !t.hot && !t.paused_at?.date && t.type !== 'application_bingo',
        )
      : [],
  ),
  pausedTasks: computed(({ tasks }) =>
    tasks?.filter(task => task?.paused_at?.date),
  ),
  bingoTask: computed(({ tasks }) =>
    !!tasks ? tasks.find(t => t.type === 'application_bingo') : [],
  ),
  declinedTask: computed(({ tasks }) =>
    !!tasks ? tasks.find(t => t.type === 'application_declined') : [],
  ),
};
