import React from 'react';
import { Trans } from 'react-i18next';
import { ClientInterviewListItem } from './ClientInterviewListItem';

export const ClientInterviewListDateGroup = ({
  clientInterviews,
  dateKey,
  onEditInterviewClick,
  onDeleteInterviewClick,
}) => {
  return (
    <div className="c-client-interviews__date-group">
      {!!dateKey && (
        <p className="c-client-interviews__date-heading">
          <Trans
            i18nKey="clientInterviews.list.dateHeading"
            values={{ date: new Date(dateKey) }}
            components={{ span: <span /> }}
          />
        </p>
      )}

      <ul className="c-client-interviews__list">
        {clientInterviews.map(clientInterview => (
          <ClientInterviewListItem
            key={clientInterview.id}
            clientInterview={clientInterview}
            onEditInterviewClick={onEditInterviewClick}
            onDeleteInterviewClick={onDeleteInterviewClick}
          />
        ))}
      </ul>
    </div>
  );
};
