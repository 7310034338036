import React, { createContext, forwardRef, useState } from 'react';
import { TaskModalSkeleton } from './TaskModalSkeleton';
import { useHistory } from 'react-router-dom';
import { TaskApplicationCustomerNotInterested } from '../TaskModalViews/TaskApplicationCustomerNotInterested';
import { TaskModalHeader } from './TaskModalHeader';
import { useAlternativeView } from './use-alternative-view';
import { useOpenedTask } from './use-opened-task';
import { TaskDefault } from '../TaskModalViews/TaskDefault';
import { TASK_TYPES } from '../../constants/task';
import { TaskApplicationCustomerInterviewingOutcome } from '../TaskModalViews/TaskApplicationCustomerInterviewingOutcome';
import { TaskApplicationClosingOne } from '../TaskModalViews/TaskApplicationClosingOne';
import { TaskApplicationClosingTwo } from '../TaskModalViews/TaskApplicationClosingTwo';
import { useFetchJob } from './use-fetch-job';
import { useFetchApplication } from './use-fetch-application';
import { useFetchTask } from './use-fetch-task';
import { TaskApplicationClosingThree } from '../TaskModalViews/TaskApplicationClosingThree';
import { TaskApplicationCustomerAdditionalInterviewOutcome } from '../TaskModalViews/TaskApplicationCustomerAdditionalInterviewOutcome';
import { TaskApplicationClosingFour } from '../TaskModalViews/TaskApplicationClosingFour';
import { TaskApplicationCurtainOpeningWaitingForFeedback } from '../TaskModalViews/TaskApplicationCurtainOpeningWaitingForFeedback';
import { TaskNewJob } from '../TaskModalViews/TaskNewJob';
import { TaskJobUpdatePresentationMeeting } from '../TaskModalViews/TaskJobUpdatePresentationMeeting';
import { TaskJobRemindPresentationMeeting } from '../TaskModalViews/TaskJobRemindPresentationMeeting';

export const TaskModalContext = createContext({
  alternativeTitle: null,
  alternativeView: null,
  onClose: () => {},
  onTaskUpdate: () => {},
  setAlternativeTitle: () => {},
  setAlternativeView: () => {},
  setTask: () => {},
  task: null,
  setApplication: () => {},
  application: null,
});

export const TaskModal = forwardRef(
  ({ onClose, onTaskUpdate, taskId }, _ref) => {
    const history = useHistory();
    const [alternativeTitle, setAlternativeTitle] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const onModalClose = () => {
      history.push({ search: '' });
      onClose();
    };

    const { isTaskLoading, setTask, task } = useFetchTask({
      onModalClose,
      taskId,
    });

    const { application, isApplicationLoading } = useFetchApplication({ task });
    const { isJobLoading, job } = useFetchJob({ task });

    useOpenedTask(setTask, task);

    const {
      AlternativeViewComponent,
      alternativeViewName,
      alternativeViewProps,
      setAlternativeView,
    } = useAlternativeView();

    const TaskDetailsViewComponent =
      !!task && TASK_TYPE_TO_TASK_VIEW_MAP[task.type]
        ? TASK_TYPE_TO_TASK_VIEW_MAP[task.type]
        : TaskDefault;

    if (!task || isTaskLoading || isApplicationLoading || isJobLoading) {
      return <TaskModalSkeleton />;
    }

    return (
      <TaskModalContext.Provider
        value={{
          alertMessage,
          alternativeTitle,
          alternativeViewName,
          application,
          job,
          onModalClose,
          onTaskUpdate,
          setAlertMessage,
          setAlternativeTitle,
          setAlternativeView,
          setTask,
          task,
        }}
      >
        <div className="c-task-modal">
          <TaskModalHeader />

          {!!AlternativeViewComponent ? (
            <AlternativeViewComponent {...alternativeViewProps} />
          ) : (
            <TaskDetailsViewComponent />
          )}
        </div>
      </TaskModalContext.Provider>
    );
  },
);

const TASK_TYPE_TO_TASK_VIEW_MAP = {
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_OUTCOME]:
    TaskApplicationCustomerInterviewingOutcome,
  [TASK_TYPES.APPLICATION_CUSTOMER_ADDITIONAL_INTERVIEW_OUTCOME]:
    TaskApplicationCustomerAdditionalInterviewOutcome,
  [TASK_TYPES.APPLICATION_CLOSING_1]: TaskApplicationClosingOne,
  [TASK_TYPES.APPLICATION_CLOSING_2]: TaskApplicationClosingTwo,
  [TASK_TYPES.APPLICATION_CLOSING_3]: TaskApplicationClosingThree,
  [TASK_TYPES.APPLICATION_CLOSING_4]: TaskApplicationClosingFour,
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_NOT_INTERESTED]:
    TaskApplicationCustomerNotInterested,
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_WAITING_FOR_FEEDBACK]:
    TaskApplicationCurtainOpeningWaitingForFeedback,
  [TASK_TYPES.JOB_UPDATE_PRESENTATION_MEETING]:
    TaskJobUpdatePresentationMeeting,
  [TASK_TYPES.NEW_JOB]: TaskNewJob,
  [TASK_TYPES.JOB_REMIND_PRESENTATION_MEETING]:
    TaskJobRemindPresentationMeeting,
};
