import React, { useContext } from 'react';
import TaskNotes from '../../TaskNotes';
import { useTranslation } from 'react-i18next';
import { TaskModalContext } from '../../TaskModal';
import {
  apiJobNoteDelete,
  apiJobNotePost,
  apiJobNoteUpdate,
} from '../../../api/job';

export const TaskDetailsNotes = ({ setIsErrorAlertVisible }) => {
  const { t } = useTranslation();
  const { task, setAlertMessage, setTask } = useContext(TaskModalContext);

  const onNoteAdded = async noteToAdd => {
    setIsErrorAlertVisible(false);

    try {
      const addedNote = await apiJobNotePost(noteToAdd.job_id, noteToAdd);

      setTask({
        ...task,
        notes: [addedNote, ...task.notes],
      });

      return addedNote;
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  const onNoteUpdated = async noteToUpdate => {
    setIsErrorAlertVisible(false);

    try {
      const updatedNote = await apiJobNoteUpdate(
        noteToUpdate.job_id,
        noteToUpdate.note_id,
        noteToUpdate,
      );

      const updatedTaskNotes = task.notes.map(taskNote =>
        taskNote.id !== noteToUpdate.note_id ? taskNote : updatedNote,
      );

      setTask({
        ...task,
        notes: updatedTaskNotes,
      });

      if (updatedNote.favorite) {
        setAlertMessage(t('note.added'));
      }

      return updatedNote;
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  const onNoteDeleted = async noteToDelete => {
    setIsErrorAlertVisible(false);

    try {
      await apiJobNoteDelete(noteToDelete.job_id, noteToDelete.note_id);

      const updatedTaskNotes = task.notes.filter(
        taskNote => taskNote.id !== noteToDelete.note_id,
      );

      setTask({
        ...task,
        notes: updatedTaskNotes,
      });

      return noteToDelete;
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return (
    <div className="c-task-details__notes">
      <TaskNotes
        canAddNote
        header={{
          title: t('note.lasts-from-job', {
            jobTitle: task.job?.title,
          }),
          icon: null,
        }}
        modifier="c-tasks--modal-notes"
        notes={task.notes}
        job={{
          ...task.job,
          job_state_progress: task.job_state_progress,
        }}
        onCreate={onNoteAdded}
        onUpdate={onNoteUpdated}
        onRemove={onNoteDeleted}
      />
    </div>
  );
};
