import { useMemo } from 'react';
import JobStateProgress, {
  JobStateModifiers,
} from '../../constants/task/jobStateProgress';

/**
 * Step modifier factory
 * @param progress, {String}
 * @param type, {String}
 * @return {*}
 */
const stepModifierFactory = (progress, type) => {
  if (['application_bingo', 'application_declined'].includes(type)) return '';

  switch (progress) {
    case JobStateProgress.PROFILING:
      return JobStateModifiers[JobStateProgress.PROFILING];
    case JobStateProgress.SEARCHING:
      return JobStateModifiers[JobStateProgress.SEARCHING];
    case JobStateProgress.CURTAIN_OPENING:
      return JobStateModifiers[JobStateProgress.CURTAIN_OPENING];
    case JobStateProgress.SCOUT_INTERVIEWING:
      return JobStateModifiers[JobStateProgress.SCOUT_INTERVIEWING];
    case JobStateProgress.CUSTOMER_INTERVIEWING:
      return JobStateModifiers[JobStateProgress.CUSTOMER_INTERVIEWING];
    case JobStateProgress.CLOSING:
      return JobStateModifiers[JobStateProgress.CLOSING];
    case JobStateProgress.ON_BOARDING:
      return JobStateModifiers[JobStateProgress.ON_BOARDING];
    default:
      return '';
  }
};

/**
 * Utility that return correct step className (called modifier)
 * based on job_state_progress value of task
 *
 * @param progress, {String}, job_state_progress value returned from server
 * @param type, {String}, type value returned from server
 * @return {String} -> Memoized string
 */
const useStepModifier = (progress, type) => {
  return useMemo(() => stepModifierFactory(progress, type), [progress, type]);
};

export default useStepModifier;
