import React, { useContext } from 'react';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../constants/task';
import { Parser } from 'html-to-react';
import { renderToString } from 'react-dom/server';
import { Action, Icon } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { TaskModalContext } from '../TaskModal';
import { useHistory } from 'react-router-dom';
import { JOB_SET_DATE_MODAL_TYPES } from '../../constants/job';

const htmlParser = new Parser();

export const PrompterItem = ({ content, item, promptType }) => {
  const ListItemComponent = PROMPT_TYPE_TO_COMPONENT_MAP[promptType];

  return (
    !!ListItemComponent && (
      <ListItemComponent
        content={content}
        item={item}
        promptType={promptType}
      />
    )
  );
};

const PrompterItemClientInterview = ({ content }) => {
  const { t } = useTranslation();

  const { onModalClose, setAlternativeView, task } =
    useContext(TaskModalContext);

  const history = useHistory();

  const onOpenClientInterviewsListClick = () => {
    history.push(`/jobs/${task.job.id}/interviews`);
    onModalClose();
  };

  return (
    <>
      <PrompterItemListItem>
        <Action
          type="simple-text"
          label={htmlParser.parse(content)}
          modifier="c-prompter__cta"
          icon="icn-calendar-event-24"
          onClick={() =>
            setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLIENT_INTERVIEW)
          }
        />
      </PrompterItemListItem>

      <PrompterItemListItem>
        <Action
          type="simple-text"
          label={t('clientInterviews.prompts.openJobInterviews')}
          modifier="c-prompter__cta"
          icon="icn-calendar-event-24"
          onClick={onOpenClientInterviewsListClick}
        />
      </PrompterItemListItem>
    </>
  );
};

const PrompterItemCall = ({ content, promptType }) => {
  const { setAlternativeView } = useContext(TaskModalContext);

  const modalType =
    promptType === TASK_PROMPT_TYPES.PROFILING_CALL_AT
      ? JOB_SET_DATE_MODAL_TYPES.PROFILING_CALL
      : JOB_SET_DATE_MODAL_TYPES.PRESENTATION_CALL;

  const onClick = () =>
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.SET_DATE, {
      modalType,
    });

  return (
    <PrompterItemListItem>
      <Action
        type="simple-text"
        label={htmlParser.parse(content)}
        modifier="c-prompter__cta"
        icon="icn-profiling-24px"
        onClick={onClick}
      />
    </PrompterItemListItem>
  );
};

const PrompterItemEmail = ({ content, item }) => {
  const { setAlternativeView } = useContext(TaskModalContext);

  const onClick = () =>
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.SEND_EMAIL, {
      templates: item.templates,
    });

  return (
    <PrompterItemListItem>
      <div data-testid="prompt-email">
        <Action
          type="simple-text"
          label={htmlParser.parse(content)}
          modifier="c-prompter__cta"
          icon="icn-mail-24px"
          onClick={onClick}
        />
      </div>
    </PrompterItemListItem>
  );
};

const PrompterItemExternalLink = ({ content }) => (
  <PrompterItemListItem>
    <p data-testid="prompt-external_link">
      <PrompterPomptContentWithLink
        htmlString={content}
        target="_blank"
        rel="noopener noreferrer"
        icon="icn-open-in-new-24px"
      />
    </p>
  </PrompterItemListItem>
);

const PrompterItemPhone = ({ content }) => (
  <PrompterItemListItem>
    <p>
      <PrompterPomptContentWithLink
        htmlString={content}
        target="_blank"
        rel="noopener noreferrer"
      />
    </p>
  </PrompterItemListItem>
);

const PrompterItemSimpleText = ({ content }) => (
  <PrompterItemListItem>
    <p>{htmlParser.parse(content)}</p>
  </PrompterItemListItem>
);

const PrompterItemWhatsapp = ({ content }) => (
  <PrompterItemListItem>
    <span className="whatsapp">
      <Icon name="icn-whatsapp-24px" modifier="u-mr-space-8" />

      <span>
        <PrompterPomptContentWithLink
          htmlString={content}
          target="_whatsapp"
          rel="noopener noreferrer"
          linkClassName="u-no-underline"
        />
      </span>
    </span>
  </PrompterItemListItem>
);

const PrompterItemCustomContent = ({ content }) => (
  <PrompterItemListItem>{content}</PrompterItemListItem>
);

const PrompterItemListItem = ({ children }) => (
  <li className="c-prompter__list-item">{children}</li>
);

const PrompterPomptContentWithLink = ({
  htmlString,
  target,
  rel,
  linkClassName,
  icon,
}) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.innerHTML = htmlString;

  const linkElement = wrapperElement.querySelector('a');

  if (!linkElement) {
    return htmlParser.parse(wrapperElement.innerHTML);
  }

  linkElement.setAttribute('target', target);
  linkElement.setAttribute('rel', rel);

  if (!!linkClassName) {
    linkElement.classList.add(linkClassName);
  }

  if (!!icon) {
    linkElement.innerHTML += renderToString(<Icon small name={icon} />);
  }

  return htmlParser.parse(wrapperElement.innerHTML);
};

const PROMPT_TYPE_TO_COMPONENT_MAP = {
  [TASK_PROMPT_TYPES.CLIENT_INTERVIEW]: PrompterItemClientInterview,
  [TASK_PROMPT_TYPES.CUSTOM_CONTENT]: PrompterItemCustomContent,
  [TASK_PROMPT_TYPES.EMAIL]: PrompterItemEmail,
  [TASK_PROMPT_TYPES.EXTERNAL_LINK]: PrompterItemExternalLink,
  [TASK_PROMPT_TYPES.PHONE]: PrompterItemPhone,
  [TASK_PROMPT_TYPES.PRESENTATION_CALL_AT]: PrompterItemCall,
  [TASK_PROMPT_TYPES.PROFILING_CALL_AT]: PrompterItemCall,
  [TASK_PROMPT_TYPES.SIMPLE_TEXT]: PrompterItemSimpleText,
  [TASK_PROMPT_TYPES.WHATSAPP]: PrompterItemWhatsapp,
};
