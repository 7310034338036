const { singleJob } = require('../__mock__/job');

const allJobs = {
  jobs: [
    {
      id: '100',
      title: 'JOB FULL SERVICE',
      customer: {
        id: '45969',
        company_name: 'Banca Interprovinciale S.p.A.',
      },
      manager: {
        id: '39462',
        email: 'elettra.paladini@r-everse.com',
        first_name: 'Elettra',
        last_name: 'Paladini',
        icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
      },
      houndListUrl:
        'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
      reverseJobUrl: 'https://app.r-everse.com/recruitment/job/1',
      pipedriveUrl: 'https://r-everse.pipedrive.com',
      success_fee: '7.200,00 €',
      code: 'REV-12345',
      publish_date: '2020-12-17 15:30:00.000000',
      numbers: singleJob.numbers,
      job_state_progress_index: 1,
      days_of_stalling: 3,
      phases: singleJob[0].phases,
      applications: singleJob[0].applications,
      frozen: true,
    },
    {
      id: '200',
      title: 'BACK END DEVELOPER',
      customer: {
        id: '45969',
        company_name: 'Qubica',
      },
      manager: {
        id: '39462',
        email: 'elettra.paladini@r-everse.com',
        first_name: 'Alessandro',
        last_name: 'Dore',
        icon: 'https://app.r-everse.com/extra-images/alessandro_dore.png',
      },
      houndListUrl:
        'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
      reverseJobUrl: 'https://app.r-everse.com/recruitment/job/1',
      pipedriveUrl: 'https://r-everse.pipedrive.com',
      success_fee: '4.700,00 €',
      code: 'REV-12346',
      publish_date: '2020-07-23 15:30:00.000000',
      job_state_progress_index: 3,
      days_of_stalling: 1,
      numbers: singleJob[0].numbers,
      applications: singleJob[0].applications,
      job_state_progress: 'Closing',
      phases: singleJob[0].phases,
    },
    {
      id: '300',
      title: 'WEB FRONT END DEVELOPER',
      customer: {
        id: '45969',
        company_name: 'Qubica',
      },
      manager: {
        id: '39462',
        email: 'elettra.paladini@r-everse.com',
        first_name: 'Elettra',
        last_name: 'Paladini',
        icon: 'https://app.r-everse.com/extra-images/elettra_paladini.png',
      },
      houndListUrl:
        'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
      reverseJobUrl: 'https://app.r-everse.com/recruitment/job/1',
      pipedriveUrl: 'https://r-everse.pipedrive.com',
      success_fee: '4.700,00 €',
      code: 'REV-12346',
      publish_date: '2021-01-04 15:30:00.000000',
      job_state_progress_index: 2,
      days_of_stalling: 4,
      numbers: singleJob[1].numbers,
      applications: singleJob[1].applications,
      phases: singleJob[1].phases,
    },
    {
      id: '400',
      title: 'UX DESIGNER',
      customer: {
        id: '45969',
        company_name: 'Deloitte',
      },
      houndListUrl:
        'https://app.r-everse.com/hound/profiles/?jobId=5c2df6b3-b5ef-4273-8c93-316d2d423698',
      reverseJobUrl: 'https://app.r-everse.com/recruitment/job/1',
      pipedriveUrl: 'https://r-everse.pipedrive.com',
      success_fee: '4.700,00 €',
      code: 'REV-12346',
      publish_date: '2021-02-23 15:30:00.000000',
      job_state_progress_index: 1,
      days_of_stalling: 2,
      numbers: singleJob[0].numbers,
      applications: singleJob[0].applications,
      job_state_progress: 'Curtain Opening',
      phases: singleJob[0].phases,
    },
  ],
  stats: {
    count: 100,
    bingo_30: 100,
    bingo_60: 200,
    bingo_90: 300,
    jarvis_usage: null,
  },
};

module.exports = { allJobs };
