import React, { useState } from 'react';
import { JobHeaderTextContent } from './JobHeaderTextContent';
import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { JobHeaderActionMenu } from './JobHeaderActionMenu';
import ExternalUrls from '../../helpers/externalUrls';
import { JobHeaderModal } from './JobHeaderModal';

export const JobHeader = ({ job, onFreezeJobClick, onAssignJobClick }) => {
  const { t } = useTranslation();
  const [isCustomerViewModalOpen, setIsCustomerViewModalOpen] = useState(false);

  const [isPresentationCallModalOpen, setIsPresentationCallModalOpen] =
    useState(false);

  const [isProfilingCallModalOpen, setIsProfilingCallModalOpen] =
    useState(false);

  const onCustomerViewClick = () => setIsCustomerViewModalOpen(true);

  const onSetPresentationCallDateClick = () =>
    setIsPresentationCallModalOpen(true);

  const onSetProfilingCallDateClick = () => setIsProfilingCallModalOpen(true);
  const onCloseCustomerViewModalClick = () => setIsCustomerViewModalOpen(false);

  const onCloseCallDateModalClick = () => {
    setIsPresentationCallModalOpen(false);
    setIsProfilingCallModalOpen(false);
  };

  return (
    <div className="c-job-header">
      <JobHeaderTextContent
        closedOnDate={job.closed_at}
        companyName={job.customer.company_name}
        jobCode={job.code}
        jobReverseUrl={job.reverse_job_url}
        jobTitle={job.title}
        publishedOnDate={job.publish_date}
        successFee={job.success_fee}
      />

      <div className="c-job-header__other-actions">
        <div className="c-job-header__guest-view">
          <Action
            type="simple-text"
            onClick={onCustomerViewClick}
            label={t('job.header.customerView')}
            icon="icn-profiling-24px"
            iconPosition="right"
          />
        </div>

        <JobHeaderActionMenu
          closeJobUrl={job.reverse_job_close_url}
          isJobFrozen={job.frozen}
          isPresentationCallDefined={!!job.presentation_call_at}
          isProfilingCallDefined={!!job.profiling_call_at}
          onFreezeJobClick={onFreezeJobClick}
          onAssignJobClick={onAssignJobClick}
          onSetPresentationCallDateClick={onSetPresentationCallDateClick}
          onSetProfilingCallDateClick={onSetProfilingCallDateClick}
          employerViewJobUrl={`${ExternalUrls.EMPLOYER_VIEW}/${job.deal_id}`}
          houndJobUrl={job.hound_list_url}
          miroUrl={job.miro_url}
          reverseJobUrl={job.reverse_job_url}
        />
      </div>

      <JobHeaderModal
        isCustomerViewModalOpen={isCustomerViewModalOpen}
        isPresentationCallModalOpen={isPresentationCallModalOpen}
        isProfilingCallModalOpen={isProfilingCallModalOpen}
        job={job}
        onCloseCustomerViewModalClick={onCloseCustomerViewModalClick}
        onCloseCallDateModalClick={onCloseCallDateModalClick}
        presentationCallDate={job.presentation_call_at}
        profilingCallDate={job.profiling_call_at}
      />
    </div>
  );
};
