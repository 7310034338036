import React, { useContext, useEffect, useRef, useState } from 'react';
import { SwitchToggle } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { ClientInterviewForm } from '../../ClientInterviewForm';
import { TaskModalContext } from '../../TaskModal';
import { useFetchedInterviews } from './use-fetched-interviews';
import { TaskClosingInterviewSkeleton } from './TaskClosingInterviewSkeleton';
import { TaskClosingContext } from './TaskClosing';

export const TaskClosingInterview = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.closing.steps.interview',
  });

  const wrapperRef = useRef(null);
  const { onClientInterviewFormChange } = useContext(TaskClosingContext);

  const [isClientInterviewFormVisible, setIsClientInterviewFormVisible] =
    useState(false);

  const { job, task } = useContext(TaskModalContext);

  const candidateFullName = !!task.application
    ? task.application.candidate_full_name
    : null;

  const applicationId = !!task.application ? task.application.uuid : null;

  const { futureInterview, isLoading, pastInterviews } =
    useFetchedInterviews(applicationId);

  const onSwitchChange = ({ target }) =>
    setIsClientInterviewFormVisible(target.checked);

  useEffect(() => {
    if (!isClientInterviewFormVisible) {
      onClientInterviewFormChange({ formData: null, isFormValid: true });
    } else if (!!wrapperRef.current) {
      wrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isClientInterviewFormVisible, wrapperRef]);

  if (isLoading) {
    return <TaskClosingInterviewSkeleton />;
  }

  return (
    <div className="c-task-closing-interview" ref={wrapperRef}>
      {!!futureInterview ? (
        <p className="c-task-closing-interview__info">
          <Trans
            i18nKey="modals.closing.steps.interview.futureInterview"
            values={futureInterview}
          />
        </p>
      ) : (
        <>
          {!!pastInterviews && (
            <p className="c-task-closing-interview__info">
              <Trans
                i18nKey="modals.closing.steps.interview.pastInterviews"
                values={pastInterviews}
              />
            </p>
          )}
          <div className="c-task-closing-interview__toggle">
            <label
              className="c-task-closing-interview__toggle-label"
              htmlFor="task-closing-interview-toggle"
            >
              {t('toggleLabel', {
                context: !!pastInterviews ? 'anotherInterview' : null,
              })}
            </label>

            <SwitchToggle
              id="task-closing-interview-toggle"
              offText={t('toggleOverlayTextOff')}
              onText={t('toggleOverlayTextOn')}
              onChange={onSwitchChange}
            />
          </div>
        </>
      )}

      {isClientInterviewFormVisible && (
        <ClientInterviewForm
          onChange={onClientInterviewFormChange}
          candidateFullName={candidateFullName}
          jobCustomer={job.customer}
          jobCustomerGuests={job.customer_guests}
        />
      )}
    </div>
  );
};
