const JobStateProgress = {
  PROFILING: 'Profiling',
  SEARCHING: 'Searching',
  CURTAIN_OPENING: 'Curtain Opening',
  CHECK_TALENT_INTEREST: 'Check talent interest',
  SCOUT_INTERVIEWING: 'Scout Interviewing',
  CUSTOMER_INTERVIEWING: 'Customer Interviewing',
  INTERVIEWING: 'Interviewing',
  ON_BOARDING: 'Onboarding',
  CLOSING: 'Closing',
  BUSINESS_OPPORTUNITY: 'Business opportunity',
};

export const JOB_STATE_PROGRESS_LIST = Object.values(JobStateProgress);

export const JobStateModifiers = {
  [JobStateProgress.PROFILING]: 'c-step-profiling',
  [JobStateProgress.SEARCHING]: 'c-step-searching',
  [JobStateProgress.CURTAIN_OPENING]: 'c-step-curtain-opening',
  [JobStateProgress.CUSTOMER_INTERVIEWING]: 'c-step-customer-interviewing',
  [JobStateProgress.SCOUT_INTERVIEWING]: 'c-step-scout-interviewing',
  [JobStateProgress.CLOSING]: '.c-step-closing',
  [JobStateProgress.ON_BOARDING]: 'c-step-onboarding',
};

export default JobStateProgress;
