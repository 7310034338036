import React from 'react';
import Heading from '../Heading';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '@reverse-hr/pattern-library';

export const JobHeaderTextContent = ({
  closedOnDate,
  companyName,
  jobCode,
  jobReverseUrl,
  jobTitle,
  publishedOnDate,
  successFee,
}) => {
  const { t } = useTranslation();

  return (
    <div className="c-job-header__text-content">
      <div className="c-job-header__title">
        <Heading tag="h1" icon="icn-job-24px" modifier="c-heading--spaceless">
          {jobTitle}
        </Heading>
      </div>

      <div className="c-job-header__summary">
        <p className="c-job-header__summary-item company-name">{companyName}</p>
      </div>

      <div className="c-job-header__summary">
        <p className="c-job-header__summary-item">
          <strong>{t('job.header.successFee', { successFee })}</strong>
        </p>

        <p className="c-job-header__summary-item">
          {!!publishedOnDate &&
            t('job.header.publishedOn', { date: new Date(publishedOnDate) })}

          {!!closedOnDate &&
            t('job.header.closedOn', { date: new Date(closedOnDate) })}

          <LinkWrapper
            modifier="u-underline"
            target="_reverse"
            type="simple-text"
            external
            url={jobReverseUrl}
          >
            {jobCode}
          </LinkWrapper>
        </p>
      </div>
    </div>
  );
};
