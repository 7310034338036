import React from 'react';
import classNames from 'classnames';

export const LoaderSpinner = ({ className, isSmall }) => {
  const wrapperClassNames = classNames('c-loader__spinner', className, {
    'c-loader__spinner--small': isSmall,
  });

  return (
    <div className={wrapperClassNames}>
      <div className="c-loader__outer"></div>
      <div className="c-loader__middle"></div>
    </div>
  );
};
