import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TaskApplicationCustomerNotInterestedFooter } from './TaskApplicationCustomerNotInterestedFooter';
import { Trans, useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { TASK_PROMPT_TYPES } from '../../../constants/task';

export const TaskApplicationCustomerNotInterested = () => {
  const { t } = useTranslation();
  const { task, job } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const reasonPrompt = {
    type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
    text: <TaskApplicationCustomerNotInterestedPrompt />,
  };

  const prompts = [reasonPrompt, ...task.prompts];

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      suggestion={t(
        'task.detailsView.applicationCustomerNotInterested.customSuggestion',
      )}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <TaskApplicationCustomerNotInterestedFooter
        setIsErrorAlertVisible={setIsErrorAlertVisible}
        customerFullName={job.customer.referent_name}
      />
    </TaskDetails>
  );
};

const TaskApplicationCustomerNotInterestedPrompt = () => {
  const { application, job } = useContext(TaskModalContext);

  return (
    <p className="c-prompter__list-item-content--new">
      <Trans
        i18nKey="task.detailsView.applicationCustomerNotInterested.prompt"
        values={{
          customerFullName: job.customer.referent_name,
          notes: application.customerInterestNotes ?? '',
        }}
      />
    </p>
  );
};
