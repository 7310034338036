import React, { useEffect, useRef } from 'react';
import { Parser } from 'html-to-react';
import { useTranslation } from 'react-i18next';

import Task from './Task';
import CelebrationWoman from '../assets/images/ill-superheroes-imperia.png';
import CelebrationMan from '../assets/images/ill-superheroes-chrono.png';
import DeclinedHero from '../assets/images/ill-declined.png';
import ActionTypes from '../constants/task/actionTypes';
import { Action, LinkWrapper } from '@reverse-hr/pattern-library';

const Feedback = ({ modifier, type, cta, task, onUpdateTask }) => {
  const { t } = useTranslation();
  const htmlParser = new Parser();
  const randomImgNumber = useRef(null);
  const prop = task.application
    ? 'application'
    : task.magnet_most_wanted
    ? 'magnet_most_wanted'
    : '';
  const candidateName = task[prop].candidate_full_name
    ? t('feedback.bingo.subtitle', { name: task[prop].candidate_full_name })
    : '';
  const bingoMessage = `${t('feedback.bingo.title', {
    name: task.manager.first_name,
  })} ${candidateName}`;
  const missedMessage = `${t('feedback.declined.title')} ${t(
    'feedback.declined.subtitle',
    {
      name: task.manager.first_name,
    },
  )}`;

  useEffect(() => {
    randomImgNumber.current = Math.round(Math.random());
  }, []);

  const completeTask = () => {
    let payload = { completed_at: true };
    if (!task.viewed_at) {
      payload.viewed_at = {
        date: new Date().toISOString().replace('T', ' ').replace('Z', ''),
        timezone_type: 3,
        timezone: 'Europe/Rome',
      };
    }
    onUpdateTask(task, payload);
  };
  const markTaskAsViewed = () => {
    if (!task.viewed_at) {
      onUpdateTask(task, {
        viewed_at: {
          date: new Date().toISOString().replace('T', ' ').replace('Z', ''),
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      });
    }
  };

  return (
    <div data-testid="feedback-container" className={`c-feedback ${modifier}`}>
      <p data-testid="feedback-message" className="c-feedback__message">
        {htmlParser.parse(type === 'bingo' ? bingoMessage : missedMessage)}
      </p>

      <div className="c-feedback__main">
        <div className="c-feedback__task">
          <Task {...task} />
        </div>
        {task.cta && (
          <div className="c-task__cta">
            {task.cta.type === ActionTypes.EXTERNAL_LINK ? (
              <LinkWrapper
                external
                type="primary"
                target="_blank"
                url={task.cta.url}
                label={task.cta.text}
                onClick={markTaskAsViewed}
              />
            ) : (
              <Action
                onClick={completeTask}
                disabled={task.cta.type === 'disabled'}
                label={task.cta.text}
              />
            )}
          </div>
        )}
      </div>
      <div className="c-feedback__illustration-container">
        {type === 'bingo' ? (
          <img
            src={!!randomImgNumber.current ? CelebrationWoman : CelebrationMan}
            className="c-feedback__ill-img"
            alt="Celebration"
          />
        ) : (
          <img
            src={DeclinedHero}
            className="c-feedback__ill-img"
            alt="Declined"
          />
        )}
      </div>
    </div>
  );
};

Feedback.defaultProps = {
  modifier: '',
  cta: null,
  task: {
    job: {
      title: '',
    },
    application: {
      candidate_full_name: null,
    },
    manager: {
      first_name: '',
    },
    modifier: '',
    job_state_progress: 'Profiling',
    icon: 'icn-curtain-opening-24px',
    description: '',
    position: '',
    customer: {
      company_name: '',
    },
    cta: {
      type: ActionTypes.EXTERNAL_LINK,
      url: 'https://app.r-everse.com',
    },
  },
  onUpdateTask: () => {},
};

export default Feedback;
