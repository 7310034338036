import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { format, isFuture } from 'date-fns';
import { ClientInterviewListDateGroup } from './ClientInterviewListDateGroup';
import { useTranslation } from 'react-i18next';
import { ClientInterviewEditModal } from '../ClientInterviewEditModal';
import { ClientInterviewListSkeleton } from './ClientInterviewListSkeleton';
import { baseJobActionsSelector } from '../../selectors/job/selectors';
import { ClientInterviewListDeleteModal } from './ClientInterviewListDeleteModal';
import { utilityGetSortedClientInterviews } from '../../utilities/client-interviews';

export const ClientInterviewList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [futureInterviews, setFutureInterviews] = useState([]);
  const [pastInterviews, setPastInterviews] = useState({});
  const [editFormData, setEditFormData] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const { thunkFetchJobClientInterviews } = useStoreActions(
    baseJobActionsSelector,
  );

  const isFutureInterviewsSectionVisible = futureInterviews.length > 0;
  const isPastInterviewsSectionVisible = Object.keys(pastInterviews).length > 0;

  const isListEmpty =
    !isFutureInterviewsSectionVisible && !isPastInterviewsSectionVisible;

  const onEditInterviewClick = editFormData => setEditFormData(editFormData);

  const onCloseModal = () => {
    setEditFormData(null);
    setDeleteModalData(null);
  };

  const onInterviewsUpdate = async () => {
    onCloseModal();
    setIsLoading(true);
    await fetchClientInterviews();
  };

  const fetchClientInterviews = async () => {
    const response = await thunkFetchJobClientInterviews();

    const groupedInterviews = getInterviewsGroupedByMonth(response.results);

    setFutureInterviews(groupedInterviews.futureInterviews);
    setPastInterviews(groupedInterviews.pastInterviews);
    setIsLoading(false);
  };

  useEffect(fetchClientInterviews, []);

  if (isLoading) {
    return <ClientInterviewListSkeleton />;
  }

  if (isListEmpty) {
    return (
      <p className="typo-text-body u-px-space-24 u-py-space-32">
        {t('clientInterviews.list.emptyList')}
      </p>
    );
  }

  return (
    <div className="c-client-interviews">
      {isFutureInterviewsSectionVisible && (
        <div className="c-client-interviews__group">
          <h4 className="c-client-interviews__heading">
            {t('clientInterviews.list.heading', { context: 'future' })}
          </h4>

          <ClientInterviewListDateGroup
            clientInterviews={futureInterviews}
            onEditInterviewClick={onEditInterviewClick}
            onDeleteInterviewClick={setDeleteModalData}
          />
        </div>
      )}

      {isPastInterviewsSectionVisible && (
        <div className="c-client-interviews__group">
          <h4 className="c-client-interviews__heading">
            {t('clientInterviews.list.heading')}
          </h4>

          {Object.keys(pastInterviews).map(dateKey => (
            <ClientInterviewListDateGroup
              key={dateKey}
              clientInterviews={pastInterviews[dateKey]}
              dateKey={dateKey}
              onEditInterviewClick={onEditInterviewClick}
              onDeleteInterviewClick={setDeleteModalData}
            />
          ))}
        </div>
      )}

      {!!editFormData && (
        <ClientInterviewEditModal
          applicationId={editFormData.applicationId}
          interviewId={editFormData.interviewId}
          candidateFullName={editFormData.candidateFullName}
          onClose={onCloseModal}
          onUpdate={onInterviewsUpdate}
        />
      )}

      {!!deleteModalData && (
        <ClientInterviewListDeleteModal
          applicationId={deleteModalData.applicationId}
          interviewId={deleteModalData.interviewId}
          onClose={onCloseModal}
          onDelete={onInterviewsUpdate}
        />
      )}
    </div>
  );
};

export const getInterviewsGroupedByMonth = interviews => {
  const sortedInterviews = utilityGetSortedClientInterviews({
    clientInterviews: interviews,
  });

  return sortedInterviews.reduce(
    (outputInterviews, interview) => {
      const interviewDate = new Date(interview.scheduledAtUtc);

      if (isFuture(interviewDate)) {
        return {
          ...outputInterviews,
          futureInterviews: [...outputInterviews.futureInterviews, interview],
        };
      }

      const interviewDateKey = format(
        new Date(interview.scheduledAtUtc),
        'yyyy-MM',
      );

      const dateGroupInterviews =
        outputInterviews.pastInterviews[interviewDateKey] || [];

      return {
        ...outputInterviews,
        pastInterviews: {
          ...outputInterviews.pastInterviews,
          [interviewDateKey]: [...dateGroupInterviews, interview],
        },
      };
    },
    {
      futureInterviews: [],
      pastInterviews: {},
    },
  );
};
