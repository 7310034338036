import React from 'react';
import type { DefaultComponentProps } from '../../types/components';
import { Icon, IconName } from '../Icon';

export interface Tab {
  active: boolean;
  label: string;
  key: string;
  icon?: IconName;
  counter?: string;
  modifier?: string;
}

export interface TabsProps extends DefaultComponentProps {
  tabs: Tab[];
  onChange: (tab: Tab) => void;
}

export const Tabs = ({ modifier, tabs, onChange }: TabsProps): JSX.Element => {
  return (
    <div data-testid="tabs-container" className={`c-tabs--${modifier}`}>
      <ul className="c-tabs__list">
        {tabs.map((tab, index) => {
          return (
            <li
              data-testid={`tabs-tab-${tab.key}`}
              key={`tab-${tab.key}-${index}`}
              onClick={() => onChange(tab)}
              className={`c-tabs__label ${
                tab.active ? 'c-tabs__label--active' : ''
              }`}
            >
              <div className="c-tabs__anchor">
                {tab.icon ? (
                  <span
                    data-testid={`tabs-icon-${tab.key}`}
                    className="c-tabs__icon"
                  >
                    <Icon name={tab.icon} />
                  </span>
                ) : null}
                {tab.counter ? (
                  <span
                    data-testid={`tabs-counter-${tab.key}`}
                    className="c-tabs__counter"
                  >
                    {tab.counter}
                  </span>
                ) : null}
                <div>
                  <p
                    data-testid={`tabs-label-${tab.key}`}
                    className="c-tabs__anchor-text"
                  >
                    {tab.label}
                  </p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Tabs.defaultProps = {
  tabs: [],
  modifier: '',
  rounded: false,
};
