import React, { useContext, useEffect, useState } from 'react';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { MISSED_REASON_LIST } from '../../../constants/applications';
import { TaskMissedCandidateReason } from './TaskMissedCandidateReason';
import { ActionWithLoader } from '../../ActionWithLoader';
import { apiApplicationDiscardUpdate } from '../../../api/applications';
import { Alert } from '@reverse-hr/pattern-library';
import { TASK_TYPES } from '../../../constants/task';

export const TaskMissedCandidate = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.missedCandidate',
  });

  const [missedReason, setMissedReason] = useState('');
  const [missedReasonNotes, setMissedReasonNotes] = useState('');
  const [submitError, setSubmitError] = useState(null);

  const { onModalClose, setAlternativeTitle, task, application } =
    useContext(TaskModalContext);

  const candidateFullName = !!task.application
    ? task.application.candidate_full_name
    : null;

  const onSubmit = async () => {
    if (!task.application || !missedReason || !missedReasonNotes) {
      return;
    }

    try {
      await apiApplicationDiscardUpdate({
        applicationId: task.application.uuid,
        reason: missedReason,
        notes: missedReasonNotes,
      });

      onModalClose();
    } catch (error) {
      setSubmitError(t('errors.generic'));
    }
  };

  const onReasonChange = ({ value }) => setMissedReason(value);
  const onMissedReasonNotesChange = notes => setMissedReasonNotes(notes);
  const onErrorClose = () => setSubmitError(null);

  useEffect(() => {
    setAlternativeTitle(t('caption', { candidate: candidateFullName }));

    return () => setAlternativeTitle(null);
  }, []);

  useEffect(() => {
    const updateMissedReason = () => {
      if (!application || !application.discard) {
        return;
      }

      setMissedReason(application.discard.reason);
    };

    const updateMissedReasonNotes = () => {
      if (
        task.type ===
        TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_NOT_INTERESTED
      ) {
        return setMissedReasonNotes(application.customerInterestNotes ?? '');
      }

      if (!application || !application.discard) {
        return;
      }

      setMissedReasonNotes(application.discard.notes ?? '');
    };

    updateMissedReason();
    updateMissedReasonNotes();
  }, []);

  const isSaveButtonDisabled = !missedReason || !missedReasonNotes;

  return (
    <>
      <TaskModalBody className="c-task-missed-candidate">
        {!!submitError && (
          <Alert
            modifier="c-alert--fixed c-task-missed-candidate__alert"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={onErrorClose}
          >
            {submitError}
          </Alert>
        )}

        <p className="c-task-missed-candidate__title">
          {t('title', { candidate: candidateFullName })}
        </p>

        <div className="c-task-missed-candidate__reasons">
          {MISSED_REASON_LIST.map(missedReasonItem => (
            <TaskMissedCandidateReason
              key={missedReasonItem}
              candidate={candidateFullName}
              missedReason={missedReasonItem}
              taskType={task.type}
              onChange={onReasonChange}
              missedReasonNotes={missedReasonNotes}
              onNotesChange={onMissedReasonNotesChange}
              isActive={missedReasonItem === missedReason}
            />
          ))}
        </div>
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          label={t('footerCta')}
          disabled={isSaveButtonDisabled}
          onClick={onSubmit}
        />
      </TaskModalFooter>
    </>
  );
};
