import React from 'react';
import { Action, Icon } from '@reverse-hr/pattern-library';
import { convert, formatDate, getProgressIcon } from '../helpers/utils';
import { Parser } from 'html-to-react';
import { useTranslation } from 'react-i18next';

import encouragement from '../assets/images/task/encouragement.png';
import hurry from '../assets/images/task/hurry.png';
import boost from '../assets/images/task/boost.png';
import anxiety from '../assets/images/task/anxiety.png';
import useStepModifier from '../helpers/hooks/useStepModifier';
import { useStoreActions } from 'easy-peasy';
import { baseTaskActionsSelector } from '../selectors/task/selectors';
import { baseTasksActionsSelector } from '../selectors/tasks/selectors';
import { TASK_TYPE_TO_KEY_MAP } from '../constants/task';

const Task = ({
  id,
  type,
  taskParentType,
  title,
  job,
  magnet,
  image_name,
  tip,
  customer,
  job_state_progress,
  viewed_at,
  paused_at,
  onClick,
  updateTasks,
  titleData,
}) => {
  const stepModifier = useStepModifier(job_state_progress, type);
  const { unpauseTask } = useStoreActions(baseTaskActionsSelector);
  const { setIsLoading } = useStoreActions(baseTasksActionsSelector);

  const { t } = useTranslation();

  // Methods
  const handleTaskClick = () => {
    onClick(id);
  };
  const handlePausedTask = async () => {
    setIsLoading(true);
    const { success, result } = await unpauseTask(id);

    if (success) {
      await updateTasks(result);
      setIsLoading(false);
    }
  };

  const images = { encouragement, hurry, boost, anxiety };

  // Render helpers
  const getModifier = () => {
    let result = '';
    if (!viewed_at) {
      result += ' c-task--notification';
    }
    if (
      job_state_progress === 'Closing' &&
      !['application_bingo', 'application_declined'].includes(type)
    ) {
      result += ' c-task--highlighted';
    }
    if (image_name) {
      result += ' c-task--image';
    }
    if (tip) {
      result += ' c-task--hint';
    }
    if (paused_at?.date) {
      result += ' c-task--postponed';
    }
    if (['application_bingo', 'application_declined'].includes(type)) {
      result += ' c-task--celebration';
    }
    return result;
  };

  const modifier = getModifier();
  const htmlParser = new Parser();
  const icon = getProgressIcon(job_state_progress);

  return (
    <div
      onClick={!paused_at ? handleTaskClick : () => null}
      data-testid={'task-wrapper'}
      data-test={`${taskParentType}-task-wrapper-${id}`}
      className={`c-task ${modifier} ${stepModifier} ${type}`}
    >
      <div className="c-task__header">
        <div className="c-task__phase">
          <div className="c-task__phase-icon">
            <Icon name={icon} />
          </div>
          <p data-testid={'task-progress'} className="c-task__phase-title">
            {job_state_progress}
          </p>
        </div>
        <p className="c-task__identity">
          <span data-testid={'task-position'} className="position">
            {!!magnet ? convert(magnet.title) : convert(job.title)}
          </span>
          <span data-testid={'task-customer'} className="customer">
            {convert(customer.company_name)}
          </span>
        </p>
      </div>
      <div className="c-task__body">
        <div className="c-task__content">
          <p data-testid={'task-description'} className="c-task__description">
            {!!image_name && (
              <span className="c-task__image">
                <img
                  data-testid="task-image"
                  src={images[image_name]}
                  alt={image_name}
                />
              </span>
            )}
            {htmlParser.parse(
              convert(
                t('task.card.title', {
                  context: TASK_TYPE_TO_KEY_MAP[type],
                  defaultValue: title,
                  ...titleData,
                }),
              ),
            )}
          </p>
        </div>
      </div>
      {tip && (
        <div className="c-task__hint">
          <p data-testid={'task-tip'} className="c-task__hint-text">
            {convert(tip)}
          </p>
        </div>
      )}
      {paused_at?.date && (
        <div className="c-task__postponed">
          <div
            className="c-task__postponed-cta"
            data-test={`${taskParentType}-task-button-${id}`}
          >
            <Action
              type="outline"
              icon="icn-resume-24px"
              onClick={handlePausedTask}
              label={t('task.reactivate')}
            />
          </div>
          <p
            className="c-task__postponed-text"
            data-test="paused-task-date"
          >{`${t('task.paused_at')}${formatDate(
            new Date(paused_at?.date),
            'dd/MM/yyyy',
          )}`}</p>
        </div>
      )}
    </div>
  );
};

Task.defaultProps = {
  job: {
    title: '',
  },
  magnet: null,
  modifier: '',
  job_state_progress: 'Profiling',
  icon: 'icn-curtain-opening-24px',
  description: '',
  position: '',
  customer: {
    company_name: '',
  },
  image_name: null,
  onClick: () => {},
  onReactivate: () => {},
};

export default Task;
