import classNames from 'classnames';
import React, { FC } from 'react';
import type { DefaultComponentProps } from '../../types/components';
import type { ObjectValues } from '../../utils/types/ObjectValues';
import type { IconName } from '../Icon';
import { CHIP_ICON_POSITIONS, ChipIcon } from './ChipIcon';

export interface ChipProps extends DefaultComponentProps {
  className?: string;
  size?: ChipSize;
  bgType?: ChipBgType;
  clickable?: boolean;
  isSelected?: boolean;
  leftIcon?: IconName;
  rightIcon?: IconName;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
  onChipClick?: () => void;
}

export const Chip: FC<ChipProps> = ({
  children,
  modifier,
  size = CHIP_SIZES.DEFAULT,
  leftIcon,
  rightIcon,
  clickable = false,
  bgType = CHIP_SIZES.DEFAULT,
  onLeftIconClick,
  onRightIconClick,
  onChipClick,
  isSelected = false,
  className,
}) => {
  const wrapperClassNames = classNames(
    'c-chip',
    modifier,
    className,
    clickable,
    {
      'c-chip--selected': isSelected,
      [`c-chip--${size}`]: Boolean(size),
      [`c-chip--${bgType}`]: Boolean(bgType),
    },
  );

  return (
    <div onClick={onChipClick} className={wrapperClassNames}>
      {leftIcon ? (
        <ChipIcon
          name={leftIcon}
          position={CHIP_ICON_POSITIONS.LEFT}
          onClick={onLeftIconClick}
        />
      ) : null}

      <p className="c-chip__text">{children}</p>

      {rightIcon ? (
        <ChipIcon
          name={rightIcon}
          position={CHIP_ICON_POSITIONS.RIGHT}
          onClick={onRightIconClick}
        />
      ) : null}
    </div>
  );
};

export const CHIP_BG_TYPES = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export type ChipBgType = ObjectValues<typeof CHIP_BG_TYPES>;

export const CHIP_SIZES = {
  DEFAULT: 'default',
  SMALL: 'small',
  LARGE: 'large',
} as const;

export type ChipSize = ObjectValues<typeof CHIP_SIZES>;
