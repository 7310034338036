const task = {
  default: {
    id: '52',
    type: 'new_job',
    customer: {
      id: '62296',
      company_name: 'transfermedia production services GmbH',
    },
    job: {
      id: '1751',
      title: 'DEFAULT TASK',
    },
    application: [],
    manager: {
      id: '26469',
      email: 'federica.boarini@r-everse.com',
      first_name: 'Federica',
      last_name: 'Boarini',
    },
    job_state_progress: 'Profiling',
    title: 'Descrizione dettagliata',
    viewed_at: {
      date: '2020-08-05',
    },
    tip: null,
    step: null,
    completed_at: null,
  },
  notification: {
    id: '41',
    type: 'job_with_publication_data',
    job: {
      id: '1506',
      title: 'NOTIFICATION TASK',
    },
    customer: {
      id: '45969',
      company_name: 'Banca Interprovinciale S.p.A.',
    },
    application: [],
    manager: {
      id: '155',
      email: 'enrica.frega@r-everse.com',
      first_name: 'Enrica',
      last_name: 'Frega',
    },
    job_state_progress: 'Searching',
    title: 'titolo',
    description: 'descrizione',
    completed_at: null,
    viewed_at: null,
    highlighted: false,
    tip: null,
  },
  highlighted: {
    id: '41',
    type: 'job_with_publication_data',
    job: {
      id: '1506',
      title: 'HIGHLIGHTED TASK',
    },
    customer: {
      id: '45969',
      company_name: 'Banca Interprovinciale S.p.A.',
    },
    application: [],
    job_state_progress: 'Curtain opening',
    manager: {
      id: '155',
      email: 'enrica.frega@r-everse.com',
      first_name: 'Enrica',
      last_name: 'Frega',
    },
    tip: null,
    title: 'titolo',
    description: 'descrizione',
    completed_at: null,
    viewed_at: {
      date: '2020-07-21',
    },
    highlighted: true,
  },
  hint: {
    id: '41',
    type: 'job_with_publication_data',
    job: {
      id: '1506',
      title: 'HINT TASK',
    },
    customer: {
      id: '45969',
      company_name: 'Banca Interprovinciale S.p.A.',
    },
    application: [],
    job_state_progress: 'Customer interviewing',
    tip: 'This task has a hint',
    manager: {
      id: '155',
      email: 'enrica.frega@r-everse.com',
      first_name: 'Enrica',
      last_name: 'Frega',
    },
    title: 'titolo',
    description: 'descrizione',
    completed_at: null,
    viewed_at: {
      date: '2020-07-21',
    },
    highlighted: false,
    hint: 'Contatta il Cliente per avere feedback.',
  },
  image: {
    id: '41',
    type: 'job_with_publication_data',
    job: {
      id: '1506',
      title: 'IMAGE TASK',
    },
    customer: {
      id: '45969',
      company_name: 'Banca Interprovinciale S.p.A.',
    },
    application: [],
    job_state_progress: 'Dealing',
    manager: {
      id: '155',
      email: 'enrica.frega@r-everse.com',
      first_name: 'Enrica',
      last_name: 'Frega',
    },
    title: 'titolo',
    description: 'descrizione',
    completed_at: null,
    viewed_at: {
      date: '2020-07-21',
    },
    highlighted: false,
    image: {
      src: 'https://r-everse-jarvis.tangible.is/images/card-image.svg',
      alt: 'Success!',
    },
  },
};

export { task };
