import React, { useContext } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { ActionWithLoader } from '../../ActionWithLoader';
import { useTaskPause } from './use-task-pause';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_TYPES,
} from '../../../constants/task';

export const TaskDetailsFooter = ({
  children,
  isPauseButtonHidden,
  setIsErrorAlertVisible,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.task.footer',
  });

  const { setAlternativeView, task } = useContext(TaskModalContext);

  const { onPauseClick, pauseButtonIcon } = useTaskPause({
    setIsErrorAlertVisible,
  });

  const onMissedCandidateClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.MISSED_CANDIDATE);
  };

  const isMissedCandidateButtonVisible =
    !!task.application &&
    !CANDIDATE_OUT_ACTION_EXCLUDED_TASK_TYPES.includes(task.type);

  return (
    <>
      {isPauseButtonHidden ? null : (
        <ActionWithLoader
          className="c-task-details__pause"
          onClick={onPauseClick}
          type="simple-text"
          icon={pauseButtonIcon}
          label={t('pauseButton', {
            context: !!task.paused_at ? 'resume' : '',
          })}
        />
      )}

      {isMissedCandidateButtonVisible && (
        <Action
          type="outline"
          onClick={onMissedCandidateClick}
          label={t('missedCandidateButton', {
            candidate: task.application.candidate_full_name,
          })}
        />
      )}

      {children}
    </>
  );
};

const CANDIDATE_OUT_ACTION_EXCLUDED_TASK_TYPES = [
  TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_NOT_INTERESTED,
  TASK_TYPES.APPLICATION_DECLINED,
  TASK_TYPES.APPLICATION_BINGO,
  TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_DAY_BEFORE,
  TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTEEN_DAYS,
  TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTY_DAYS,
  TASK_TYPES.JOB_REMIND_PRESENTATION_MEETING,
];
