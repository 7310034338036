const navigation = {
  items: {
    tasks: {
      type: 'task',
      label: 'Tasks',
      path: '/tasks',
      modifier: 'main',
      has_updates: true,
      active: true,
    },
    jobs: {
      type: 'job',
      label: 'All jobs',
      path: '/jobs',
      modifier: 'job',
      subitems: [],
    },
  },
  modifier: '',
};

module.exports = navigation;
