const IS_ENV_PRODUCTION =
  !!process && !!process.env && process.env.NODE_ENV === 'production';

export const REACT_APP_HOST = IS_ENV_PRODUCTION
  ? 'https://app.reverse.hr'
  : 'http://localhost:3000';

export const APP_LOGIN_URL = IS_ENV_PRODUCTION
  ? 'https://app.reverse.hr/login'
  : 'http://localhost/login';
