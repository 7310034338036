export const utilityGetSortedClientInterviews = ({
  clientInterviews,
  sortOrder = UTILITY_CLIENT_INTERVIEWS_SORT_ORDERS.DESC,
}) => {
  clientInterviews.sort((a, b) =>
    sortOrder === UTILITY_CLIENT_INTERVIEWS_SORT_ORDERS.DESC
      ? new Date(b.scheduledAtUtc) - new Date(a.scheduledAtUtc)
      : new Date(a.scheduledAtUtc) - new Date(b.scheduledAtUtc),
  );

  return clientInterviews;
};

export const UTILITY_CLIENT_INTERVIEWS_SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};
