const user = {
  id: 20927,
  email: 'silvia.fiori@r-everse.com',
  first_name: 'Silvia',
  last_name: 'Fiori',
  icon: 'https://r-everse-jarvis.tangible.is/images/recruiter-image.png',
  locale: 'it',
  jarvis_token:
    'e_KPx2-C62Eo39pzP_UvNf:APA91bGcZSjUxAD5L-QkIfwwWnAHfQbJGXP9BvMrAMFTiCZQMvWUD-Sqn_uPQrPMk0AMwJr1yaliPIr8GIXiqKLi0VodEEPSemIHpqEvKp2Fb4UK9BqmpgnR5mvNbTDb8JTXn5nTV75Z',
  amIImpersonatingSomeoneElse: true,
};

module.exports = user;
