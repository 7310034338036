import { action, computed, thunk } from 'easy-peasy';
import i18next from 'i18next';
import isEqual from 'lodash.isequal';
import { apiJobClientInterviewsFetch } from '../api/job';

export default {
  // state
  fetchingId: null,
  job: {},
  jobTasks: [],
  jobTasksLoading: true,
  jobTasksError: false,
  jobNotes: [],
  jobNotesLoading: [],
  jobNotesError: [],
  jobActivities: [],
  jobHorserace: {},
  jobActivitiesLoading: true,
  jobActivitiesError: false,
  // jobHorseraceLoading: true,
  // jobHorseraceError: false,
  jobEmails: [],
  jobEmailsLoading: true,
  jobEmailsError: false,
  jobManagers: [],
  jobManagersLoading: true,
  jobManagersError: false,
  isFetching: false,
  isLoading: true,
  isError: false,
  jobCustomerViewShortLink: null,

  // actions
  setJob: action((state, payload) => {
    state.job = payload;
  }),
  setJobTasks: action((state, payload) => {
    state.jobTasks = payload;
  }),
  setJobNotes: action((state, payload) => {
    state.jobNotes = payload;
  }),
  setJobActivities: action((state, payload) => {
    state.jobActivities = payload;
  }),
  setJobHorserace: action((state, payload) => {
    state.jobHorserace = payload;
  }),
  setJobEmails: action((state, payload) => {
    state.jobEmails = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setJobTasksLoading: action((state, payload) => {
    state.jobTasksLoading = payload;
  }),
  setJobNotesLoading: action((state, payload) => {
    state.jobNotesLoading = payload;
  }),
  setJobActivitiesLoading: action((state, payload) => {
    state.jobActivitiesLoading = payload;
  }),
  // setJobHorseraceLoading: action((state, payload) => {
  //   state.jobHorseraceLoading = payload
  // }),
  setJobEmailsLoading: action((state, payload) => {
    state.jobEmailsLoading = payload;
  }),
  setJobTasksError: action((state, payload) => {
    state.jobTasksError = payload;
  }),
  setJobNotesError: action((state, payload) => {
    state.jobNotesError = payload;
  }),
  setJobActivitiesError: action((state, payload) => {
    state.jobActivitiesError = payload;
  }),
  // setJobHorseraceError: action((state, payload) => {
  //   state.jobHorseraceError = payload
  // }),
  setJobEmailsError: action((state, payload) => {
    state.jobEmailsError = payload;
  }),
  setJobManagers: action((state, payload) => {
    state.jobManagers = payload;
  }),
  setJobManagersLoading: action((state, payload) => {
    state.jobManagersLoading = payload;
  }),
  setJobManagersError: action((state, payload) => {
    state.jobManagersError = payload;
  }),
  setIsFetching: action((state, payload) => {
    state.isFetching = payload;
  }),
  setFetchingId: action((state, payload) => {
    state.fetchingId = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),
  setJobCustomerViewShortLink: action((state, payload) => {
    state.jobCustomerViewShortLink = payload;
  }),
  resetJob: action((state, payload) => {
    // console.log('reset job model')
    state.job = {};
    state.jobTasks = [];
    state.jobTasksLoading = true;
    state.jobTasksError = false;
    state.jobNotes = [];
    state.jobNotesLoading = true;
    state.jobNotesError = false;
    state.jobActivities = [];
    state.jobActivitiesLoading = true;
    state.jobActivitiesError = false;
    state.jobHorserace = {};
    state.jobHorseraceLoading = true;
    state.jobHorseraceError = false;
    state.isFetching = true;
    state.isLoading = true;
    state.isError = false;
    state.jobCustomerViewShortLink = null;
  }),

  // thunks
  fetchJob: thunk(
    async (
      actions,
      payload,
      { getState, getStoreActions, getStoreState, injections },
    ) => {
      const { job } = getState();
      const {
        jobsService: { getJob },
      } = injections;
      // Se chiamo la stessa job e non arriva da Firebase non faccio nulla
      if (job.id === payload.id && !payload.shouldNotify) {
        return new Promise(resolve => resolve());
      } else {
        actions.resetJob();
        actions.setFetchingId(payload.id);
      }

      const {
        navigation: {
          navigation: {
            items: {
              jobs: { subitems },
            },
          },
        },
      } = getStoreState();

      return new Promise(async (resolve, reject) => {
        try {
          const fetchedJob = await getJob(payload.id);
          const { fetchingId } = getState();

          // Avoid race condition when several requests are done simultaneously
          if (fetchingId && fetchedJob.id !== fetchingId) {
            resolve();
            return;
          }
          resolve(fetchedJob);

          // Notifico solo se la chiamata arriva da Firebase e se i tasks presenti cambiano
          // Prendo i dati dello state solo adesso perché posso aver resettato la job nel frattempo
          const { jobNotes, jobTasks } = getState();
          const tasksIds = jobTasks.map(t => t.id);
          const fetchedTasksIds = fetchedJob.tasks.map(t => t.id);
          const shouldNotify = payload.shouldNotify
            ? jobTasks.length
              ? fetchedTasksIds.join('') !== tasksIds.join('')
              : false
            : false;

          actions.setJob(fetchedJob);
          if (!isEqual(jobTasks, fetchedJob.tasks)) {
            actions.setJobTasks(fetchedJob.tasks);
          }
          if (!isEqual(jobNotes, fetchedJob.notes)) {
            actions.setJobNotes(fetchedJob.notes);
          }
          if (!isEqual(jobNotes, fetchedJob.notes)) {
            actions.setJobHorserace(fetchedJob.applications);
          }
          actions.setJobTasksLoading(false);
          actions.setJobNotesLoading(false);
          actions.setIsLoading(false);
          actions.setIsFetching(false);

          if (!subitems.length) {
            getStoreActions().navigation.fetchJobs();
          }

          if (shouldNotify) {
            const newTasks = fetchedJob.tasks.filter(
              t => !tasksIds.includes(t.id),
            );
            const completedTasks = jobTasks.filter(
              t => !fetchedTasksIds.includes(t.id),
            );
            // console.log(`Notify: ${newTasks.length} new tasks, ${completedTasks.length} completed`)

            const completedMessage = completedTasks.length
              ? completedTasks.length === 1
                ? i18next.t('notifications.completed-task')
                : i18next.t('notifications.completed-tasks', {
                    number: completedTasks.length,
                  })
              : '';
            const newTasksMessage = newTasks.length
              ? newTasks.length === 1
                ? i18next.t('notifications.new-task')
                : i18next.t('notifications.new-tasks', {
                    number: newTasks.length,
                  })
              : '';
            getStoreActions().setNotifications({
              id: `${tasksIds.join('').slice(0, 10)}-${Date.parse(new Date())}`,
              visible: true,
              types: ['positive', 'raw'],
              message: `${completedMessage} ${newTasksMessage}`,
            });
          }
        } catch (error) {
          console.warn(error);
          actions.setIsError(true);
          actions.setIsLoading(false);
          actions.setIsFetching(false);
          reject();
        }
      });
    },
  ),
  thunkFetchJobClientInterviews: thunk(async (actions, _, { getState }) => {
    try {
      const { job } = getState();

      return await apiJobClientInterviewsFetch(job.uuid);
    } catch (error) {
      actions.setIsError(true);
    }
  }),
  // fetchJobHorserace: thunk(async (actions, payload, { getState, injections }) => {
  //   const { id } = payload
  //   const {
  //     jobsService: { getJobHorserace }
  //   } = injections
  //   actions.setJobHorseraceError(false)
  //   try {
  //     const horserace = await getJobHorserace(id)
  //     if (!!horserace) {
  //       actions.setJobHorserace(horserace)
  //     }
  //   } catch (error) {
  //     console.warn(error)
  //     actions.setJobHorseraceError(true)
  //   } finally {
  //     actions.setJobHorseraceLoading(false)
  //   }
  // }),
  fetchJobActivities: thunk(
    async (actions, payload, { getState, injections }) => {
      const {
        job: { id },
      } = getState();
      const {
        jobsService: { getJobActivities },
      } = injections;
      actions.setJobActivitiesError(false);
      try {
        const activities = await getJobActivities(id);
        if (!!activities) {
          actions.setJobActivities(activities);
        }
      } catch (error) {
        console.warn(error);
        actions.setJobActivitiesError(true);
      } finally {
        actions.setJobActivitiesLoading(false);
      }
    },
  ),
  fetchJobEmails: thunk(async (actions, payload, { getState, injections }) => {
    const {
      job: { id },
    } = getState();
    const {
      jobsService: { getJobEmails },
    } = injections;
    actions.setJobEmailsError(false);
    try {
      const emails = await getJobEmails(id, payload.threadId);
      if (!!emails) {
        actions.setJobEmails(emails);
      }
    } catch (error) {
      console.warn(error);
      actions.setJobEmailsError(true);
    } finally {
      actions.setJobEmailsLoading(false);
    }
  }),
  updateJob: thunk(async (actions, payload, { injections }) => {
    const { id, ...content } = payload;
    const {
      jobsService: { updateJob },
    } = injections;
    actions.setIsLoading(true);
    actions.setIsFetching(true);
    try {
      const updatedJob = await updateJob(id, content);
      if (!!updatedJob) {
        actions.setJob(updatedJob);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      // TODO: notifica?
      actions.setIsLoading(false);
      actions.setIsFetching(false);
    }
  }),
  updateJobNotes: thunk(async (actions, payload, { getState }) => {
    const { jobNotes } = getState();
    const updatedNotes = jobNotes.map(note => {
      if (note.id === payload.id) {
        return payload;
      }
      return note;
    });
    actions.setJobNotes(updatedNotes);
  }),
  getManagers: thunk(async (actions, payload, { injections }) => {
    const {
      jobsService: { getManagers },
    } = injections;
    actions.setJobManagersLoading(true);
    actions.setJobManagersError(false);
    try {
      const managers = await getManagers();
      if (managers) {
        actions.setJobManagers(managers);
      }
    } catch (error) {
      console.warn(error);
      actions.setJobManagersError(true);
    } finally {
      actions.setJobManagersLoading(false);
    }
  }),
  getCustomerViewShortLink: thunk(async (actions, payload, { injections }) => {
    const {
      jobsService: { getCustomerViewShortLink },
    } = injections;
    return new Promise(async (resolve, reject) => {
      try {
        const { url } = await getCustomerViewShortLink(payload);
        if (url) {
          actions.setJobCustomerViewShortLink(url);
          resolve(url);
        }
      } catch (error) {
        console.warn(error);
        reject('Error fetching the short link');
      }
    });
  }),
  updateJobTasksListWithUpdatedTaskItem: thunk(
    (actions, payload, { getState }) => {
      const { jobTasks } = getState();

      const newTasks = jobTasks.map(task =>
        task.id === payload?.id ? payload : task,
      );
      actions.setJobTasks(newTasks);
    },
  ),
  // computed
  jobHotTasks: computed(({ jobTasks }) =>
    !!jobTasks
      ? jobTasks.filter(
          t => !!t.hot && !t?.paused_at?.date && t.type !== 'application_bingo',
        )
      : [],
  ),
  jobToDoTasks: computed(({ jobTasks }) =>
    !!jobTasks
      ? jobTasks.filter(
          t => !t.hot && !t?.paused_at?.date && t.type !== 'application_bingo',
        )
      : [],
  ),
  jobPausedTasks: computed(({ jobTasks }) =>
    !!jobTasks ? jobTasks.filter(t => t?.paused_at?.date) : [],
  ),
  jobFavoriteNotes: computed(({ jobNotes }) =>
    !!jobNotes ? jobNotes.filter(n => !!n.favorite) : [],
  ),
  jobNormalNotes: computed(({ jobNotes }) =>
    !!jobNotes ? jobNotes.filter(n => !n.favorite) : [],
  ),

  actionSetJobProfilingCallDate: action((state, profilingCall) => {
    if (state.job) {
      state.job.profiling_call_at = profilingCall;
    }
  }),

  actionSetJobPresentationCallDate: action((state, presentationCall) => {
    if (state.job) {
      state.job.presentation_call_at = presentationCall;
    }
  }),
};
