import React from 'react';
import Notice from './Notice';
import { error } from '../helpers/default-values';

const Error = ({
  componentStack,
  error: { message, stack },
  eventId,
  resetError,
}) => {
  return (
    <div className="c-notice--error-general--wrapper">
      <Notice type="error-general" title={error().title} text={error().text} />
    </div>
  );
};

export default Error;
