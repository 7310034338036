import React, { useState, forwardRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';

import { Loader } from '../components/Loader';
import { Action, Icon } from '@reverse-hr/pattern-library';

const CustomerViewLinkModal = forwardRef(
  ({ title, customer, jobId, onClose }, ref) => {
    const htmlParser = new Parser();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    // STATE
    const customerViewShortLink = useStoreState(
      state => state.job.jobCustomerViewShortLink,
    );
    // ACTIONS
    const getCustomerViewShortLink = useStoreActions(
      actions => actions.job.getCustomerViewShortLink,
    );

    const handleGetLink = async () => {
      setLoading(true);
      const short_url = await getCustomerViewShortLink(jobId);
      await navigator.clipboard.writeText(short_url);
      setLoading(false);
    };

    return (
      <>
        <div className="c-modal__content">
          <div className="c-job-customer-link__close">
            <span onClick={onClose}>
              <Icon name="icn-close-24px" />
            </span>
          </div>
          <div className="c-job-customer-link">
            <p className="c-job-customer-link__title">{title}</p>
            <p className="c-job-customer-link__company">
              {customer.company_name}
            </p>
            <p className="c-job-customer-link__subtitle">
              {customerViewShortLink
                ? htmlParser.parse(t('job.header.customer_link.label_copied'))
                : htmlParser.parse(t('job.header.customer_link.label'))}
            </p>
          </div>
        </div>
        <footer className="c-modal__footer">
          <div className="c-modal__footer-cta c-job-customer-link__footer">
            {loading ? (
              <Loader text={t('loading')} />
            ) : (
              <>
                <p className="c-job-customer-link__link">
                  <a
                    href={customerViewShortLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {customerViewShortLink}
                  </a>
                  {!loading && customerViewShortLink ? (
                    <span>{t('job.header.customer_link.link_copied')}</span>
                  ) : null}
                </p>

                {!!customerViewShortLink ? (
                  <Action
                    onClick={onClose}
                    label={t('job.header.customer_link.copied')}
                  />
                ) : (
                  <Action
                    onClick={handleGetLink}
                    label={t('job.header.customer_link.cta')}
                  />
                )}
              </>
            )}
          </div>
        </footer>
      </>
    );
  },
);

export default CustomerViewLinkModal;
