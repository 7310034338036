import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { ActionWithLoader } from '../../ActionWithLoader';
import { JOB_SET_DATE_MODAL_TYPES } from '../../../constants/job';
import { apiJobUpdate } from '../../../api/job';
import { format } from 'date-fns';
import { useStoreActions } from 'easy-peasy';
import { baseJobActionsSelector } from '../../../selectors/job/selectors';
import { SetMeetingDateForm } from '../../SetMeetingDateForm';

export const TaskSetDate = ({ modalType }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.setMeetingDate',
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const { job, onModalClose, setAlternativeTitle, task } =
    useContext(TaskModalContext);

  const { setJob } = useStoreActions(baseJobActionsSelector);

  const onDateChange = ({ date }) => setSelectedDate(date);
  const onErrorAlertClose = () => setIsErrorAlertVisible(false);

  const onSubmit = async () => {
    setIsErrorAlertVisible(false);

    try {
      const updatedJob = await apiJobUpdate({
        jobId: task.job.id,
        payload: {
          [MODAL_TYPE_TO_PAYLOAD_KEY_MAP[modalType]]: format(
            new Date(selectedDate),
            'yyyy-MM-dd HH:mm:ss',
          ),
        },
      });

      setJob(updatedJob);
      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  useEffect(() => {
    setAlternativeTitle(
      t('alternativeHeaderTitle', {
        context: modalType,
        customerFullName: job.customer.referent_name,
      }),
    );

    return () => {
      setAlternativeTitle(null);
    };
  }, []);

  return (
    <>
      <TaskModalBody className="c-task-set-date">
        <SetMeetingDateForm
          isErrorAlertVisible={isErrorAlertVisible}
          datePickerProps={{
            isRequired: true,
            label: {
              id: 'set-meeting-date',
              text: t('dateLabel'),
            },
          }}
          title={t('title', { context: modalType })}
          onChange={onDateChange}
          onErrorAlertClose={onErrorAlertClose}
        />
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader onClick={onSubmit} label={t('cta')} />
      </TaskModalFooter>
    </>
  );
};

const MODAL_TYPE_TO_PAYLOAD_KEY_MAP = {
  [JOB_SET_DATE_MODAL_TYPES.PRESENTATION_CALL]: 'presentation_call_at',
  [JOB_SET_DATE_MODAL_TYPES.PROFILING_CALL]: 'profiling_call_at',
};
