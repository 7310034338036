import React, { useState } from 'react';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { AppModal } from '../AppModal';
import { useTranslation } from 'react-i18next';
import { apiClientInterviewDelete } from '../../api/client-interviews';
import { ActionWithLoader } from '../ActionWithLoader';

export const ClientInterviewListDeleteModal = ({
  applicationId,
  interviewId,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'clientInterviews.list.deleteModal',
  });

  const [deleteError, setDeleteError] = useState(null);

  const isModalVisible = !!applicationId && !!interviewId;

  const onDeleteConfirmClick = async () => {
    try {
      await apiClientInterviewDelete({ applicationId, interviewId });

      onDelete();
    } catch (error) {
      setDeleteError(error);
    }
  };

  const onErrorAlertClose = () => setDeleteError(null);

  return (
    <>
      <AppModal
        isVisible={isModalVisible}
        className="c-client-interview-delete-modal c-modal--small c-modal--auto-height"
      >
        <h2 className="c-client-interview-delete-modal__title">{t('title')}</h2>

        <p className="c-client-interview-delete-modal__content">
          {t('content')}
        </p>

        {!!deleteError && (
          <Alert
            icon="icn-warning-outline-24"
            type="warning"
            onClose={onErrorAlertClose}
          >
            {t('error')}
          </Alert>
        )}

        <footer className="c-client-interview-delete-modal__footer">
          <ActionWithLoader
            modifier="c-client-interview-delete-modal__action"
            label={t('confirm')}
            type="outline"
            onClick={onDeleteConfirmClick}
          />

          <Action
            modifier="c-client-interview-delete-modal__action"
            label={t('cancel')}
            onClick={onClose}
          />
        </footer>
      </AppModal>
    </>
  );
};
