import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { ActionWithLoader } from '../../ActionWithLoader';
import { TASK_PROMPT_TYPES } from '../../../constants/task';
import { apiJobSendApplicationsToClientPost } from '../../../api/job';

export const TaskJobRemindPresentationMeeting = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'task.detailsView.jobRemindPresentationMeeting',
  });
  const { onModalClose, job } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t('prompts.clientCvFeedback'),
    },
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t('prompts.jobDetailsEmailSending'),
    },
  ];

  const onSendShortlistToCustomer = async () => {
    setIsErrorAlertVisible(false);

    try {
      await apiJobSendApplicationsToClientPost(job.uuid);

      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      title={t('title', {
        customerFullName: job.customer.referent_name,
      })}
      prompts={prompts}
      suggestion={t('suggestion')}
      isPauseButtonHidden
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <ActionWithLoader
        modifier="c-action--send-shortlist"
        onClick={onSendShortlistToCustomer}
        label={t('cta', {
          customerFullName: job.customer.referent_name,
        })}
      />
    </TaskDetails>
  );
};
