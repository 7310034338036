export const TASK_TYPES = {
  APPLICATION_BINGO: 'application_bingo',
  APPLICATION_CLOSING_1: 'application_closing_1',
  APPLICATION_CLOSING_2: 'application_closing_2',
  APPLICATION_CLOSING_3: 'application_closing_3',
  APPLICATION_CLOSING_4: 'application_closing_4',
  APPLICATION_CURTAIN_OPENING_CUSTOMER_INTERESTED_NO_SCOUT:
    'application_curtain_opening_customer_interested_no_scout',
  APPLICATION_CURTAIN_OPENING_WAITING_FOR_FEEDBACK:
    'application_curtain_opening_waiting_for_feedback',
  APPLICATION_CUSTOMER_ADDITIONAL_INTERVIEW_OUTCOME:
    'application_customer_additional_interview_outcome',
  APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_INCOMING:
    'application_customer_fifth_interviewing_incoming',
  APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_OUTCOME:
    'application_customer_fifth_interviewing_outcome',
  APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_INCOMING:
    'application_customer_fourth_interviewing_incoming',
  APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_OUTCOME:
    'application_customer_fourth_interviewing_outcome',
  APPLICATION_CUSTOMER_INTERVIEWING_INCOMING:
    'application_customer_interviewing_incoming',
  APPLICATION_CUSTOMER_INTERVIEWING_OUTCOME:
    'application_customer_interviewing_outcome',
  APPLICATION_CUSTOMER_INTERVIEWING_TO_BE_CREATED:
    'application_customer_interviewing_to_be_created',
  APPLICATION_CUSTOMER_SECOND_INTERVIEWING_INCOMING:
    'application_customer_second_interviewing_incoming',
  APPLICATION_CUSTOMER_SECOND_INTERVIEWING_OUTCOME:
    'application_customer_second_interviewing_outcome',
  APPLICATION_CUSTOMER_THIRD_INTERVIEWING_INCOMING:
    'application_customer_third_interviewing_incoming',
  APPLICATION_CUSTOMER_THIRD_INTERVIEWING_OUTCOME:
    'application_customer_third_interviewing_outcome',
  APPLICATION_DECLINED: 'application_declined',
  APPLICATION_HIRED_FOLLOW_UP_DAY_BEFORE:
    'application_hired_follow_up_day_before',
  APPLICATION_HIRED_FOLLOW_UP_FIFTEEN_DAYS:
    'application_hired_follow_up_fifteen_days',
  APPLICATION_HIRED_FOLLOW_UP_FIFTY_DAYS:
    'application_hired_follow_up_fifty_days',
  APPLICATION_HIRED_FOLLOW_UP_THREE_MONTHS:
    'application_hired_follow_up_three_months',
  APPLICATION_SCOUT_CUSTOMER_INTERESTED:
    'application_scout_customer_interested',

  APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_NO_FEEDBACK:
    'application_scout_customer_not_interested_no_feedback',
  APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_WITH_FEEDBACK:
    'application_scout_customer_not_interested_with_feedback',

  APPLICATION_CURTAIN_OPENING_CUSTOMER_NOT_INTERESTED:
    'application_curtain_opening_customer_not_interested',

  APPLICATION_SCOUT_PREVIOUS_REPORT_USED:
    'application_scout_previous_report_used',
  APPLICATION_SCOUT_REPORT_MISSING: 'application_scout_report_missing',
  APPLICATION_SCOUT_REPORT_RECEIVED: 'application_scout_report_received',
  JOB_RECAP_EMAIL_TO_BE_SENT: 'job_recap_email_to_be_sent',
  JOB_UPDATE_PRESENTATION_MEETING: 'job_update_jra_data',
  JOB_WITHOUT_SCOUT: 'job_without_scout',
  JOB_WITH_CUSTOMER_VIEW: 'job_with_customer_view',
  JOB_WITH_INCOMING_JRA: 'job_with_incoming_jra',
  JOB_WITH_PUBLICATION_DATA: 'job_with_publication_data',
  JOB_REMIND_PRESENTATION_MEETING: 'job_remind_presentation_meeting',
  NEW_JOB: 'new_job',
  NEW_JOB_WITH_EXPLORATION_CALL_DONE: 'new_job_with_exploration_call_done',
  NEW_JOB_WITH_EXPLORATION_CALL_SCHEDULED:
    'new_job_with_exploration_call_scheduled',
};

export const TASK_TYPE_LIST = Object.values(TASK_TYPES);

export const TASK_PHASES = {
  PROFILING: 'profiling',
  SEARCHING: 'searching',
  CURTAIN_OPENING: 'curtainOpening',
  SCOUT_INTERVIEWING: 'scoutInterviewing',
  CUSTOMER_INTERVIEWING: 'customerInterviewing',
  CLOSING: 'closing',
  ON_BOARDING: 'onBoarding',
};

export const TASK_TYPE_TO_PHASE_MAP = {
  [TASK_TYPES.NEW_JOB]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_WITHOUT_SCOUT]: TASK_PHASES.PROFILING,
  [TASK_TYPES.NEW_JOB_WITH_EXPLORATION_CALL_SCHEDULED]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_RECAP_EMAIL_TO_BE_SENT]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_UPDATE_PRESENTATION_MEETING]: TASK_PHASES.PROFILING,
  [TASK_TYPES.NEW_JOB_WITH_EXPLORATION_CALL_DONE]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_WITH_CUSTOMER_VIEW]: TASK_PHASES.SEARCHING,
  [TASK_TYPES.JOB_WITH_PUBLICATION_DATA]: TASK_PHASES.SEARCHING,
  [TASK_TYPES.JOB_WITH_INCOMING_JRA]: TASK_PHASES.SEARCHING,
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_WAITING_FOR_FEEDBACK]:
    TASK_PHASES.CURTAIN_OPENING,
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_INTERESTED_NO_SCOUT]:
    TASK_PHASES.CURTAIN_OPENING,
  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_INTERESTED]:
    TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_NO_FEEDBACK]:
    TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_WITH_FEEDBACK]:
    TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCOUT_PREVIOUS_REPORT_USED]:
    TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCOUT_REPORT_MISSING]: TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCOUT_REPORT_RECEIVED]:
    TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_TO_BE_CREATED]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_INCOMING]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_INCOMING]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_INCOMING]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_SECOND_INTERVIEWING_INCOMING]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_SECOND_INTERVIEWING_OUTCOME]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_THIRD_INTERVIEWING_INCOMING]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_OUTCOME]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_OUTCOME]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_OUTCOME]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_CUSTOMER_THIRD_INTERVIEWING_OUTCOME]:
    TASK_PHASES.CUSTOMER_INTERVIEWING,
  [TASK_TYPES.APPLICATION_BINGO]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_CLOSING_1]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_DECLINED]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_CLOSING_2]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_CLOSING_3]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_CLOSING_4]: TASK_PHASES.CLOSING,
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_DAY_BEFORE]: TASK_PHASES.ON_BOARDING,
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTEEN_DAYS]:
    TASK_PHASES.ON_BOARDING,
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTY_DAYS]: TASK_PHASES.ON_BOARDING,
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_THREE_MONTHS]:
    TASK_PHASES.ON_BOARDING,
};

export const TASK_MODAL_ALTERNATIVE_VIEWS = {
  BINGO: 'bingo',
  CLIENT_INTERVIEW: 'clientInterview',
  CLOSING: 'closing',
  MISSED_CANDIDATE: 'missedCandidate',
  SEND_EMAIL: 'sendEmail',
  SET_DATE: 'setDate',
};

export const TASK_PROMPT_TYPES = {
  SIMPLE_TEXT: 'simple_text',
  EXTERNAL_LINK: 'external_link',
  PHONE: 'phone',
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
  PROFILING_CALL_AT: 'profiling_call_at',
  PRESENTATION_CALL_AT: 'presentation_call_at',
  CLIENT_INTERVIEW: 'client_interview',
  CUSTOM_CONTENT: 'custom_content',
};

export const TASK_TYPE_TO_KEY_MAP = {
  [TASK_TYPES.APPLICATION_BINGO]: 'applicationBingo',
  [TASK_TYPES.APPLICATION_CLOSING_1]: 'applicationClosingOne',
  [TASK_TYPES.APPLICATION_CLOSING_2]: 'applicationClosingTwo',
  [TASK_TYPES.APPLICATION_CLOSING_3]: 'applicationClosingThree',
  [TASK_TYPES.APPLICATION_CLOSING_4]: 'applicationClosingFour',
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_INTERESTED_NO_SCOUT]:
    'applicationCurtainOpeningCustomerInterestedNoScout',
  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_WAITING_FOR_FEEDBACK]:
    'applicationCurtainOpeningWaitingForFeedback',
  [TASK_TYPES.APPLICATION_CUSTOMER_ADDITIONAL_INTERVIEW_OUTCOME]:
    'applicationCustomerAdditionalInterviewOutcome',
  [TASK_TYPES.APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_INCOMING]:
    'applicationCustomerFifthInterviewingIncoming',
  [TASK_TYPES.APPLICATION_CUSTOMER_FIFTH_INTERVIEWING_OUTCOME]:
    'applicationCustomerFifthInterviewingOutcome',
  [TASK_TYPES.APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_INCOMING]:
    'applicationCustomerFourthInterviewingIncoming',
  [TASK_TYPES.APPLICATION_CUSTOMER_FOURTH_INTERVIEWING_OUTCOME]:
    'applicationCustomerFourthInterviewingOutcome',
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_INCOMING]:
    'applicationCustomerInterviewingIncoming',
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_OUTCOME]:
    'applicationCustomerInterviewingOutcome',
  [TASK_TYPES.APPLICATION_CUSTOMER_INTERVIEWING_TO_BE_CREATED]:
    'applicationCustomerInterviewingToBeCreated',
  [TASK_TYPES.APPLICATION_CUSTOMER_SECOND_INTERVIEWING_INCOMING]:
    'applicationCustomerSecondInterviewingIncoming',
  [TASK_TYPES.APPLICATION_CUSTOMER_SECOND_INTERVIEWING_OUTCOME]:
    'applicationCustomerSecondInterviewingOutcome',
  [TASK_TYPES.APPLICATION_CUSTOMER_THIRD_INTERVIEWING_INCOMING]:
    'applicationCustomerThirdInterviewingIncoming',
  [TASK_TYPES.APPLICATION_CUSTOMER_THIRD_INTERVIEWING_OUTCOME]:
    'applicationCustomerThirdInterviewingOutcome',
  [TASK_TYPES.APPLICATION_DECLINED]: 'applicationDeclined',
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_DAY_BEFORE]:
    'applicationHiredFollowUpDayBefore',
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTEEN_DAYS]:
    'applicationHiredFollowUpFifteenDays',
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_FIFTY_DAYS]:
    'applicationHiredFollowUpFiftyDays',
  [TASK_TYPES.APPLICATION_HIRED_FOLLOW_UP_THREE_MONTHS]:
    'applicationHiredFollowUpThreeMonths',
  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_INTERESTED]:
    'applicationScoutCustomerInterested',

  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_NO_FEEDBACK]:
    'applicationScoutCustomerNotInterestedNoFeedback',
  [TASK_TYPES.APPLICATION_SCOUT_CUSTOMER_NOT_INTERESTED_WITH_FEEDBACK]:
    'applicationScoutCustomerNotInterestedWithFeedback',

  [TASK_TYPES.APPLICATION_CURTAIN_OPENING_CUSTOMER_NOT_INTERESTED]:
    'applicationCurtainOpeningCustomerNotInterested',

  [TASK_TYPES.APPLICATION_SCOUT_PREVIOUS_REPORT_USED]:
    'applicationScoutPreviousReportUsed',
  [TASK_TYPES.APPLICATION_SCOUT_REPORT_MISSING]:
    'applicationScoutReportMissing',
  [TASK_TYPES.APPLICATION_SCOUT_REPORT_RECEIVED]:
    'applicationScoutReportReceived',
  [TASK_TYPES.JOB_RECAP_EMAIL_TO_BE_SENT]: 'jobRecapEmailToBeSent',
  [TASK_TYPES.JOB_UPDATE_JRA_DATA]: 'jobUpdateJraData',
  [TASK_TYPES.JOB_WITHOUT_SCOUT]: 'jobWithoutScout',
  [TASK_TYPES.JOB_WITH_CUSTOMER_VIEW]: 'jobWithCustomerView',
  [TASK_TYPES.JOB_WITH_INCOMING_JRA]: 'jobWithIncomingJra',
  [TASK_TYPES.JOB_WITH_PUBLICATION_DATA]: 'jobWithPublicationData',
  [TASK_TYPES.JOB_REMIND_PRESENTATION_MEETING]: 'jobRemindPresentationMeeting',
  [TASK_TYPES.NEW_JOB]: 'newJob',
  [TASK_TYPES.NEW_JOB_WITH_EXPLORATION_CALL_DONE]:
    'newJobWithExplorationCallDone',
  [TASK_TYPES.NEW_JOB_WITH_EXPLORATION_CALL_SCHEDULED]:
    'newJobWithExplorationCallScheduled',
};
