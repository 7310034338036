export const JOB_TABS = {
  NOTES: 'notes',
  ACTIVITIES: 'activities',
  INTERVIEWS: 'interviews',
};

export const JOB_SET_DATE_MODAL_TYPES = {
  POSTPONE_TASK: 'postponeTask',
  PRESENTATION_CALL: 'presentationCall',
  PROFILING_CALL: 'profilingCall',
};
