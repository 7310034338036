import { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { selectorJobState } from '../../selectors/job/selectors';
import { useParams } from 'react-router-dom';
import { apiJobFetch } from '../../api/job';

export const useFetchJob = ({ task }) => {
  const [isJobLoading, setIsJobLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [job, setJob] = useState(null);
  const { id: queryJobId } = useParams();
  const { job: storeJob } = useStoreState(selectorJobState);

  useEffect(() => {
    const fetchJob = async () => {
      if (!task || !!job || isFetching) {
        return;
      }

      if (!!queryJobId && queryJobId === task.job.id) {
        return;
      }

      if (!!storeJob.id && storeJob.id === task.job.id) {
        return;
      }

      try {
        setIsFetching(true);

        const fetchedJob = await apiJobFetch(task.job.id);

        setJob(fetchedJob);
      } finally {
        setIsFetching(false);
        setIsJobLoading(false);
      }
    };

    fetchJob();
  }, [task, queryJobId, storeJob.id]);

  useEffect(() => {
    if (!!storeJob && !!task && storeJob.id === task.job.id) {
      setJob(storeJob);
      setIsJobLoading(false);
    }
  }, [task, storeJob]);

  return { isJobLoading, job };
};
