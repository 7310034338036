import React, { forwardRef } from 'react';
import type { DefaultComponentProps } from '../../types/components';
import { Icon, IconName } from '../Icon';

export interface LinkWrapperProps extends DefaultComponentProps {
  label?: string;
  children?: React.ReactElement;
  external?: boolean;
  url?: string;
  type:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'raw'
    | 'unstyled'
    | 'simple-text';
  icon?: IconName;
  iconOnly?: boolean;
  iconPosition?: 'right' | 'left';
  shadow?: boolean;
  target?: '_self' | '_blank';
  download?: boolean;
  onClick?: () => void;
}

type Ref = HTMLAnchorElement;

/**
 * I'm using a forwardRef in order to pass this component as a prop to ReactRouter link
 * See: https://reactrouter.com/web/api/Link/component-reactcomponent
 * In this way this component is not linked to the implementation and can be used (theoretically) with an other router
 *
 * The name of the component is LinkWrapper to avoid conflict with router implementations
 */
export const LinkWrapper = forwardRef<Ref, LinkWrapperProps>(
  (
    {
      modifier,
      type,
      icon,
      iconOnly,
      iconPosition,
      label,
      external,
      url,
      shadow,
      children,
      target,
      download,
      onClick,
      ...props
    },
    ref,
  ): JSX.Element => {
    const linkProps = external ? { href: url } : { ref, ...props };

    return (
      <a
        {...linkProps}
        target={target}
        download={download}
        onClick={onClick}
        data-testid="link-wrapper"
        className={
          type !== 'unstyled'
            ? `c-action c-action--${type} ${
                icon && iconOnly ? 'c-action--iconized' : ''
              } ${!shadow ? 'c-action--slender' : ''} ${modifier}`
            : ''
        }
      >
        {icon && iconPosition === 'left' ? (
          <span
            data-testid="link-wrapper-icon-left"
            className="c-action__icon c-action__icon--main"
          >
            <Icon name={icon} />
          </span>
        ) : null}
        {!!label && !children ? (
          <span
            data-testid="link-wrapper-label"
            className={type !== 'unstyled' ? `c-action__text` : ''}
          >
            {label}
          </span>
        ) : null}
        {children ? children : null}
        {icon && iconPosition === 'right' ? (
          <span
            data-testid="link-wrapper-icon-right"
            className="c-action__icon c-action__icon--support"
          >
            <Icon name={icon} />
          </span>
        ) : null}
      </a>
    );
  },
);

LinkWrapper.defaultProps = {
  modifier: '',
  type: 'unstyled',
  iconPosition: 'left',
  iconOnly: false,
  shadow: true,
  target: '_self',
  download: false,
  onClick: () => {},
};
