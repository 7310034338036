import React, { useState } from 'react';
import classNames from 'classnames';
import CustomerViewLinkModal from '../CustomerViewLinkModal';
import { AppModal } from '../AppModal';
import { SetMeetingDateModal } from '../SetMeetingDateModal';
import { JOB_SET_DATE_MODAL_TYPES } from '../../constants/job';
import { apiJobUpdate } from '../../api/job';
import { useHandleError } from '../../helpers/hooks/use-handle-error';
import { useTranslation } from 'react-i18next';
import { Alert } from '@reverse-hr/pattern-library';
import { useStoreActions } from 'easy-peasy';
import { baseJobActionsSelector } from '../../selectors/job/selectors';

export const JobHeaderModal = ({
  isCustomerViewModalOpen,
  isPresentationCallModalOpen,
  isProfilingCallModalOpen,
  job,
  onCloseCustomerViewModalClick,
  onCloseCallDateModalClick,
  presentationCallDate,
  profilingCallDate,
}) => {
  const { t } = useTranslation();

  const { handledError, setHandledError, handleError } = useHandleError({
    genericError: t('modals.setMeetingDate.error'),
  });

  const { actionSetJobProfilingCallDate, actionSetJobPresentationCallDate } =
    useStoreActions(baseJobActionsSelector);

  const isSetDateModalVisible =
    isPresentationCallModalOpen || isProfilingCallModalOpen;

  const modalClassName = classNames({
    'c-modal--job-customer-link c-modal--small c-modal--auto-height':
      isCustomerViewModalOpen,
    'c-modal--set-date c-modal--small c-modal--auto-height':
      isSetDateModalVisible,
  });

  const isModalOpen = isCustomerViewModalOpen || isSetDateModalVisible;

  const callDateModalType = isPresentationCallModalOpen
    ? JOB_SET_DATE_MODAL_TYPES.PRESENTATION_CALL
    : JOB_SET_DATE_MODAL_TYPES.PROFILING_CALL;

  const callDateModalInitialValue = isPresentationCallModalOpen
    ? presentationCallDate
    : profilingCallDate;

  const onCallDateModalSubmit = async ({ name, value }) => {
    try {
      await apiJobUpdate({
        jobId: job.id,
        payload: {
          [name]: value,
        },
      });

      onCloseCallDateModalClick();

      if (name === 'presentation_call_at') {
        return actionSetJobPresentationCallDate(value);
      }

      actionSetJobProfilingCallDate(value);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AppModal isVisible={isModalOpen} className={modalClassName}>
      <div className="c-modal__wrapper">
        {isCustomerViewModalOpen && (
          <CustomerViewLinkModal
            title={job.title}
            customer={job.customer}
            jobId={job.id}
            onClose={onCloseCustomerViewModalClick}
          />
        )}

        {isSetDateModalVisible && (
          <SetMeetingDateModal
            initialValue={callDateModalInitialValue}
            modalType={callDateModalType}
            onClose={onCloseCallDateModalClick}
            onSubmit={onCallDateModalSubmit}
          />
        )}

        {!!handledError && (
          <Alert
            modifier="c-alert--fixed"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={() => setHandledError(null)}
          >
            {handledError}
          </Alert>
        )}
      </div>
    </AppModal>
  );
};
