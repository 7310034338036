import React, { useState } from 'react';
import type { DefaultComponentProps } from '../../types/components';
import { Action } from '../Action';
import type {
  ButtonLayout,
  ButtonPosition,
  ButtonSize,
  ButtonType,
} from '../Action/types';
import { Icon, IconName } from '../Icon';

export interface AlertProps extends DefaultComponentProps {
  children: string | JSX.Element | React.ReactNode;
  type: 'default' | 'positive' | 'negative' | 'warning';
  icon: IconName;
  closable?: boolean;
  onClose?: () => void;
  hasCta?: boolean;
  ctaType?: ButtonType;
  ctaLayout?: ButtonLayout;
  ctaLabel?: string;
  ctaIconOnly?: boolean;
  ctaIcon?: IconName;
  ctaIconPosition?: ButtonPosition;
  ctaDisabled?: boolean;
  ctaShadow?: boolean;
  ctaSize?: ButtonSize;
  ctaOnClick?: () => void;
  ctaModifier?: string;
}

export const Alert = ({
  modifier,
  children,
  type = 'warning',
  icon = 'icn-error-outline-24',
  closable,
  onClose,
  hasCta,
  ctaType,
  ctaLayout,
  ctaLabel,
  ctaIconOnly,
  ctaIcon,
  ctaIconPosition = 'right',
  ctaDisabled,
  ctaShadow,
  ctaSize = 'small',
  ctaOnClick,
  ctaModifier,
}: AlertProps): JSX.Element | null => {
  const [opened, setOpened] = useState(true);

  const handleClose = () => {
    setOpened(false);
    if (onClose) {
      onClose();
    }
  };
  return opened ? (
    <div
      data-testid="alert-container"
      className={`c-alert ${modifier} ${
        type !== 'default' ? `c-alert--${type}` : ''
      }`}
    >
      {icon ? (
        <div data-testid="alert-icon" className="c-alert__icon">
          <Icon name={icon} />
        </div>
      ) : null}

      <div>
        <div data-testid="alert-text" className="c-alert__text">
          {children}
        </div>
        {hasCta ? (
          <div className="c-alert__cta">
            <Action
              buttonType={ctaType}
              type={ctaLayout}
              label={ctaLabel}
              iconOnly={ctaIconOnly}
              icon={ctaIcon}
              iconPosition={ctaIconPosition}
              disabled={ctaDisabled}
              shadow={ctaShadow}
              size={ctaSize}
              onClick={ctaOnClick}
              modifier={ctaModifier}
            />
          </div>
        ) : null}
      </div>

      {closable ? (
        <div className="c-alert__close">
          <button
            data-testid="alert-close-btn"
            className="c-alert__close-btn"
            onClick={handleClose}
          >
            <Icon name="icn-close-big-24" />
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
};

Alert.defaultProps = {
  type: 'default',
  modifier: '',
  icon: 'icn-warning-outline-24',
  closable: true,
};
