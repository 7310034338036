import React, { ChangeEvent } from 'react';
import type { PropsWithClassName } from '../../types/components';

export interface ITextAreaProps {
  id?: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

export const TextArea: React.FC<PropsWithClassName<ITextAreaProps>> = ({
  className,
  id,
  value,
  onChange,
  rows = 4,
  disabled,
  readOnly,
  placeholder,
}) => {
  let classes = `c-textarea ${className}`;

  if (disabled) {
    classes = `c-textarea c-textarea--disabled`;
  }

  if (readOnly) {
    classes = `c-textarea c-textarea--readonly`;
  }

  return (
    <div className={classes}>
      <textarea
        id={id}
        className="c-textarea__control"
        rows={rows}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};
